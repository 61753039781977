import { Text } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { ISongs } from '../../config';
import { EventTypes } from '../../global.d';
import { setCurrency } from '../../gql/cache';
import { normalizeCoins, showCurrency } from '../../utils';
import type Animation from '../animations/animation';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import { TweenProperties } from '../animations/d';
import Tween from '../animations/tween';
import { ViewContainer } from '../components/ViewContainer';
import {
  BASE_WIN_TITLE_SCALE,
  COUNT_UP_MESSAGE_X,
  COUNT_UP_MESSAGE_Y,
  MAXIMUM_FRACTION_DIGITS,
  MINIMUM_FRACTION_DIGITS,
  eventManager,
  winValueStyles,
} from '../config';

class WinCountUpMessage extends ViewContainer {
  public winValue = 0.0;

  public winCountUpAnimation: Animation | null = null;

  private winTitle = new Text(
    this.winValue.toLocaleString('en-EN', {
      minimumFractionDigits: MINIMUM_FRACTION_DIGITS,
      maximumFractionDigits: MAXIMUM_FRACTION_DIGITS,
    }),
    winValueStyles,
  );

  constructor() {
    super();
    this.winTitle.y = COUNT_UP_MESSAGE_Y;
    this.winTitle.x = COUNT_UP_MESSAGE_X;
    this.winTitle.anchor.set(0.5, 0.5);
    this.winTitle.visible = false;
    this.addChild(this.winTitle);
    eventManager.addListener(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION, this.skipWinCountUpAnimation.bind(this));
    eventManager.addListener(EventTypes.START_COUNT_UP, this.startCountUp.bind(this));
    eventManager.addListener(EventTypes.HIDE_COUNT_UP, this.hideCountUp.bind(this));
    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, () => {
      this.winValue = 0.0;
    });
  }

  private hideCountUp(durationTime?: number): void {
    const duration = durationTime === undefined ? 1000 : 0;
    const hideCountUpMassage = new Tween({
      propertyBeginValue: 1,
      target: 0,
      object: this.winTitle,
      easing: (n) => Math.pow(n, 8),
      property: TweenProperties.ALPHA,
      duration,
    });
    hideCountUpMassage.addOnComplete(() => {
      this.winTitle.visible = false;
      this.winTitle.alpha = 1;
    });
    hideCountUpMassage.addOnSkip(() => {
      this.winTitle.visible = false;
      this.winTitle.alpha = 1;
    });
    hideCountUpMassage.start();
  }

  private startCountUp(start: number, end: number, id: number): void {
    if (end <= 0) return;
    this.winTitle.visible = true;
    const normalizedStart = normalizeCoins(start);
    const normalizedEnd = normalizeCoins(end);
    const countUpAnimation = new AnimationChain();
    const baseWinAnimation = this.createBaseWinAnimation(normalizedStart, normalizedEnd, id);
    baseWinAnimation.addOnStart(() => {
      this.winTitle.visible = true;
      AudioApi.play({ type: ISongs.SFX_SM_CountUp_Loop, stopPrev: true });
    });
    baseWinAnimation.addOnComplete(() => {
      eventManager.emit(EventTypes.COUNT_UP_END);
      AudioApi.stop({ type: ISongs.SFX_SM_CountUp_Loop });
      this.winCountUpAnimation = null;
    });
    baseWinAnimation.addOnSkip(() => {
      eventManager.emit(EventTypes.COUNT_UP_END);
      AudioApi.stop({ type: ISongs.SFX_SM_CountUp_Loop });
      this.setWinValue(normalizedEnd);
      this.winCountUpAnimation = null;
    });
    countUpAnimation.appendAnimation(baseWinAnimation);

    this.winCountUpAnimation = countUpAnimation;
    this.winCountUpAnimation.start();
  }

  private skipWinCountUpAnimation() {
    this.winCountUpAnimation?.skip();
  }

  private createBaseWinAnimation(start: number, end: number, id: number): Animation {
    const baseWinAnimation = new AnimationGroup({});
    const duration = 1000;
    const countUpAnimation = new Tween({
      propertyBeginValue: start,
      target: end,
      object: this,
      property: TweenProperties.WIN_VALUE,
      update: this.setWinValue.bind(this),
      duration,
    });
    const scaleXAnimation = new Tween({
      object: this.winTitle.scale,
      propertyBeginValue: 1,
      target: BASE_WIN_TITLE_SCALE,
      property: TweenProperties.X,
      duration,
    });
    const scaleYAnimation = new Tween({
      object: this.winTitle.scale,
      propertyBeginValue: 1,
      target: BASE_WIN_TITLE_SCALE,
      property: TweenProperties.Y,
      duration,
    });
    if (id !== -1) {
      baseWinAnimation.addAnimation(scaleXAnimation);
      baseWinAnimation.addAnimation(scaleYAnimation);
    }
    baseWinAnimation.addAnimation(countUpAnimation);
    return baseWinAnimation;
  }

  public setWinValue(winValue: number): void {
    this.winValue = winValue < 0 ? 0 : winValue;
    this.winTitle.text = `${formatNumber({
      currency: setCurrency(),
      value: winValue,
      showCurrency: showCurrency(setCurrency()),
    })}`;
  }
}

export default WinCountUpMessage;

import type { Group, Layer } from '@pixi/layers';

import { SlotId } from '../../../config';
import { EventTypes, GameMode, ISpinAndGrabFeature, ISpinAndGrabSlot } from '../../../global.d';
import { setCurrentBonus, setCurrentIsTurboSpin, setCurrentSpinAndGrabRound, setSlotConfig } from '../../../gql/cache';
import { Logic } from '../../../logic';
import AnimationGroup from '../../animations/animationGroup';
import { TweenProperties } from '../../animations/d';
import type { BaseAnimation } from '../../animations/reel/baseAnimation';
import type { ReelAnimation } from '../../animations/reel/reelAnimation';
import Tween from '../../animations/tween';
import { ViewContainer } from '../../components/ViewContainer';
import {
  BASE_REEL_ENDING_FORMULA,
  GRAB_AND_SPIN_REELS_AMOUNT,
  REELS_AMOUNT,
  ReelState,
  SLOTS_PER_REEL_AMOUNT,
  SLOT_HEIGHT,
  SPIN_AND_GRAB_SLOTS_PER_REEL_AMOUNT,
  eventManager,
} from '../../config';
import type { Icon } from '../../d';
import type { BaseReel } from '../reel/baseReel';
import { GrabAndSpinReel } from '../reel/grabAndSpinReel';
import type { Reel } from '../reel/reel';

class GrabAndSpinReelsContainer extends ViewContainer {
  private reelSpinOrderMap: { [x: string]: number } = {
    '1': 3,
    '0': 0,
    '2': 6,
    '3': 9,
    '4': 12,
    '5': 1,
    '6': 4,
    '7': 7,
    '8': 10,
    '9': 13,
    '10': 2,
    '11': 5,
    '12': 8,
    '13': 11,
    '14': 14,
  };

  private spinAndGrabReelSymbolsList = [SlotId.C, SlotId.M, SlotId.E];

  public reels: (Reel & ViewContainer)[] = [];

  public landedReels: number[] = [];

  public reelsToSpin: (Reel & ViewContainer)[] = [];

  public isSoundPlayed = false;

  public isForceStopped = false;

  public layer!: Layer;

  public slotGroup!: Group;

  public grabAndSpinFeatureConfig!: ISpinAndGrabFeature;

  constructor() {
    super();

    this.visible = false;

    eventManager.on(EventTypes.ENTER_SPIN_AND_GRAB_MODE, () => {
      this.grabAndSpinFeatureConfig = setCurrentBonus().data.spinAndGrabFeature as ISpinAndGrabFeature;
      const initialReelSymbols = this.grabAndSpinFeatureConfig.startingCells.map(
        (spinAndGrabSlot) => spinAndGrabSlot.symbol,
      );
      const initialReelPositions = initialReelSymbols.map((initialReelSymbol) =>
        this.spinAndGrabReelSymbolsList.findIndex((listSymbol) => listSymbol === initialReelSymbol),
      );

      this.removeChildren();
      this.reelsToSpin = [];
      this.initReels(initialReelPositions, this.slotGroup);
    });
    eventManager.addListener(EventTypes.SPIN_AND_GRAB_REEL_LANDED, this.checkLandedReels.bind(this));
    eventManager.addListener(
      EventTypes.SET_SPIN_AND_GRAB_SLOTS_VISIBILITY,
      this.setSpinAndGrabSlotsVisibility.bind(this),
    );
    eventManager.addListener(EventTypes.START_SPIN_AND_GRAB_ANIMATION, () => {
      this.landedReels = [];
      this.isForceStopped = false;
      this.getSpinAnimation().start();
    });
    eventManager.addListener(EventTypes.SET_SPIN_AND_GRAB_COLLECT_VALUE, this.setCollectSymbolValue.bind(this));
    eventManager.addListener(
      EventTypes.RESET_MULTIPLIER_ON_FINAL_COLLECT_FEATURE,
      this.resetMultiplierOnFinalCollectFeature.bind(this),
    );
    this.sortableChildren = true;
  }

  override onModeChange(settings: { mode: GameMode }): void {
    this.visible = settings.mode === GameMode.SPIN_AND_GRAB;
  }

  public initReels(startPositions: number[], slotGroup: Group): void {
    for (let i = 0; i < GRAB_AND_SPIN_REELS_AMOUNT; i++) {
      const position = startPositions[i as number] || 0;
      const reel = new GrabAndSpinReel(i, this.spinAndGrabReelSymbolsList, position, slotGroup);
      this.reels[i as number] = reel;

      this.addChild(reel);
    }
  }

  public getSpinAnimation(): AnimationGroup {
    const animationGroup = new AnimationGroup();
    this.reelsToSpin = this.reels
      .filter((reel) => !reel.isFreezed)
      .sort((reel1, reel2) => this.reelSpinOrderMap[reel1.id]! - this.reelSpinOrderMap[reel2.id]!);

    for (let i = 0; i < this.reelsToSpin.length; i++) {
      const reel = this.reelsToSpin[i as number];
      const spinAnimation: ReelAnimation = reel!.createSpinAnimation();

      if (i === this.reelsToSpin.length - 1) {
        spinAnimation.getWaiting().addOnChange(() => {
          if (!Logic.the.isReadyForStop) {
            Logic.the.isReadyForStop = true;
            this.removeErrorHandler();

            for (let j = 0; j < this.reelsToSpin.length; j++) {
              const stopAfter = 666 * (j + 1);

              setTimeout(() => this.setupReelsTarget(j), stopAfter);
            }
          }
        });
        spinAnimation.getWaiting().addOnComplete(this.throwTimeoutError);
      }
      this.reelsToSpin[i as number]!.isPlaySoundOnStop = true;
      animationGroup.addAnimation(spinAnimation);
    }

    return animationGroup;
  }

  public setupReelsTarget(index: number): void {
    const isStopped = Logic.the.isStoppedBeforeResult;
    const isTurboSpin = setCurrentIsTurboSpin() && Logic.the.controller.gameMode === GameMode.BASE_GAME;

    const currentRound = setCurrentSpinAndGrabRound();
    const speed = isTurboSpin ? 40 : 20;
    const waitingDuration = isTurboSpin ? 1000 : 2500;
    const spinedReel = this.reelsToSpin[index as number] as GrabAndSpinReel;
    const waitingAnimation = spinedReel.animation!.getWaiting() as Tween;
    const diff = (speed * waitingDuration) / 1000 - SPIN_AND_GRAB_SLOTS_PER_REEL_AMOUNT;
    const emptySymbolPosition = this.spinAndGrabReelSymbolsList.findIndex((symbolId) => symbolId === SlotId.E);
    const reelWinConfig = currentRound.newCells.filter(
      (cell) => cell.position === this.reelsToSpin[index as number]!.id,
    )[0];
    const reelStopPosition = reelWinConfig
      ? this.spinAndGrabReelSymbolsList.findIndex((symbolId) => symbolId === reelWinConfig.symbol)
      : emptySymbolPosition;
    this.isSoundPlayed = false;

    // Freeze reel if it is not empty
    if (reelStopPosition !== emptySymbolPosition) {
      this.reels.filter((reel) => reel.id === spinedReel.id)[0]!.isFreezed = true;
    }

    const beginValue = reelStopPosition + diff;
    waitingAnimation.propertyBeginValue = -7; // beginValue;
    waitingAnimation.target = 1; // beginValue - diff + 1;
    waitingAnimation.duration = isStopped ? 0 : 500;
    const appearingBegin = beginValue - diff;
    const appearingDuration = isTurboSpin ? 250 : 500;
    const appearingAnimation = new Tween({
      object: this.reelsToSpin[index as number]!,
      target: reelStopPosition,
      propertyBeginValue: appearingBegin + 3,
      property: TweenProperties.STOP_POSITION,
      easing: BASE_REEL_ENDING_FORMULA,
      duration: isStopped ? 0 : appearingDuration,
    });
    appearingAnimation.addOnStart(() => {
      this.reelsToSpin[index as number]!.changeState(ReelState.APPEARING);
      setTimeout(() => this.reelsToSpin[index as number]!.slots[reelStopPosition as number]!.onSlotStopped(), 300);
    });
    appearingAnimation.addOnChange(() => {
      this.reelsToSpin[index as number]!.slots.forEach((slot, _i) => {
        slot.y = this.reelsToSpin[index as number]!.getSlotY(slot);
      });
    });
    appearingAnimation.addOnComplete(() => {
      this.reelsToSpin[index as number]!.changeState(ReelState.IDLE);

      eventManager.emit(EventTypes.SPIN_AND_GRAB_REEL_LANDED, index);
    });

    spinedReel.animation?.appendAnimation(appearingAnimation);
  }

  public checkLandedReels(id: number): void {
    this.landedReels.push(id);
    if (this.landedReels.length === this.reelsToSpin.length) {
      this.landedReels = [];
      this.reelsToSpin = [];

      eventManager.emit(EventTypes.REELS_STOPPED);
    }
  }

  private throwTimeoutError(): void {
    eventManager.emit(EventTypes.THROW_ERROR);
  }

  private removeErrorHandler(): void {
    this.reelsToSpin[this.reelsToSpin.length - 1]!.animation?.getWaiting().removeOnComplete(this.throwTimeoutError);
  }

  public getCurrentSpinResult(): Icon[] {
    const spinResult: Icon[] = [];
    for (let j = 0; j < SLOTS_PER_REEL_AMOUNT; j++) {
      for (let i = 0; i < REELS_AMOUNT; i++) {
        spinResult.push(
          setSlotConfig().icons.find((icon) => {
            const reel = this.reels[i as number] as BaseReel;
            return (
              icon.id ===
              reel.slots.find((slot) => slot.id === (reel.stopPosition + j + reel.size - 1) % reel.size)!.slotId
            );
          })!,
        );
      }
    }
    return spinResult;
  }

  public rollbackReels(): void {
    for (let i = 0; i < REELS_AMOUNT; i++) {
      this.reels[i as number]!.animation?.getDisappearing().end();
      this.reels[i as number]!.animation?.getWaiting().end();
      this.reels[i as number]!.slots.forEach((slot, id) => {
        slot.y = (id + 0.5) * SLOT_HEIGHT;
      });
    }
  }

  public forceStopReels(): void {
    this.isForceStopped = true;
    for (let i = 0; i < REELS_AMOUNT; i++) {
      const animation = this.reels[i as number]!.animation! as BaseAnimation;
      animation.getWaiting().duration = 0;
      animation.getAppearing().duration = 20;
    }
  }

  private setCollectSymbolValue(collectorSymbol: ISpinAndGrabSlot): void {
    this.reels[collectorSymbol.position]!.slots.filter((slot) => slot.slotId === SlotId.C)[0]!.setCollectMultiplier(
      collectorSymbol.value as number,
    );
  }

  private resetMultiplierOnFinalCollectFeature(symbol: ISpinAndGrabSlot): void {
    this.reels[symbol.position]!.slots.filter((slot) => slot.slotId === symbol.symbol)[0]!.resetCollectMultiplier();
  }

  public setSpinAndGrabSlotsVisibility(symbols: ISpinAndGrabSlot[], visibility: boolean): void {
    symbols.forEach((symbol) => {
      this.reels[symbol.position]!.slots.filter((slot) => slot.slotId === symbol.symbol)[0]!.visible = visibility;
    });
  }
}

export default GrabAndSpinReelsContainer;

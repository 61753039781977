import i18n from 'i18next';
import { ITextStyle, Sprite, Texture, isMobile } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs, mappedAudioSprites } from '../../../config';
import { EventTypes } from '../../../global.d';
import {
  setIsContinueAutoSpinsAfterFeature,
  setIsDuringBigWinLoop,
  setIsShowSoundToast,
  setIsSoundLoading,
} from '../../../gql/cache';
import { Logic } from '../../../logic';
import { delayedAction, handleChangeRestriction } from '../../../utils';
import type Animation from '../../animations/animation';
import { TweenProperties } from '../../animations/d';
import Tween from '../../animations/tween';
import { TextField } from '../../components/TextField';
import type { ViewContainer } from '../../components/ViewContainer';
import { eventManager } from '../../config';
import type { PopupProps } from '../../d';
import { Popup } from '../popup';

import SpinAndGrabStartPopupBackgroundAnimated from './spinAndGrabStartPopupBackgroundAnimated';

const spinAndGrabTextStyle: Partial<ITextStyle> = {
  dropShadow: true,
  dropShadowAngle: 8,
  dropShadowColor: '#750000',
  dropShadowDistance: 3,
  fill: ['#ffc05c', '#ffae00', '#bd7400', '#bd7400', '#ad5400', '#e08a00'],
  fillGradientStops: [0.6],
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 95,
  lineJoin: 'round',
  miterLimit: 65,
  stroke: '#4d1700',
  strokeThickness: 9,
  whiteSpace: 'normal',
};
const youWonTextStyle: Partial<ITextStyle> = {
  align: 'center',
  dropShadowAngle: -1.2,
  dropShadowBlur: 9,
  dropShadowColor: '#fff700',
  dropShadowDistance: 0,
  fill: 'white',
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 100,
  miterLimit: 48,
  stroke: '#2e0000',
  strokeThickness: 1,
  whiteSpace: 'normal',
  wordWrapWidth: 140,
};
const pressToContinueTextStyle: Partial<ITextStyle> = {
  align: 'center',
  dropShadowAngle: -1.2,
  dropShadowBlur: 9,
  dropShadowColor: '#fff700',
  dropShadowDistance: 0,
  fill: '#1e400a',
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 70,
  stroke: '#2e0000',
  strokeThickness: 1,
  whiteSpace: 'normal',
  wordWrapWidth: 140,
};

export class SpinAndGrabStartPopup extends Popup {
  protected background!: Sprite;

  protected popupBackground!: SpinAndGrabStartPopupBackgroundAnimated;

  protected youWonText!: TextField;

  protected spinAndGrabText!: TextField;

  protected pressToContinueText!: TextField;

  protected textContainer!: ViewContainer;

  public fadeOutAnimation!: Animation;

  public fadeInAnimation!: Animation;

  public backgroundFadeInAnimation!: Animation;

  public backgroundFadeOutAnimation!: Animation;

  protected autoSkipDelay: ReturnType<typeof setTimeout> | undefined;

  constructor() {
    super();
    this.interactive = true;
    this.visible = false;
    this.initPopupBackground();
    this.initPopup();
  }

  protected initPopup(): void {
    this.addChild(this.background);
    this.addChild(this.popupBackground);
    this.initSpinAndGrabText();
    this.initYouWonText();
    this.initPressToContinueText();
    this.popupBackground.spine.slotContainers[
      this.popupBackground.spine.skeleton.findSlotIndex('123456789')
    ]!.removeChildren();
    this.popupBackground.spine.slotContainers[
      this.popupBackground.spine.skeleton.findSlotIndex('congratulations')
    ]!.removeChildren();
  }

  private initPopupBackground(): void {
    this.background = new Sprite(Texture.WHITE);
    this.background.tint = 0x000000;
    this.background.anchor.set(0.5, 0.5);
    this.background.alpha = 0.5;

    this.popupBackground = new SpinAndGrabStartPopupBackgroundAnimated(this);
  }

  private initYouWonText(): void {
    this.youWonText = new TextField(i18n.t('freeSpinsYouWon'), 500, 100, youWonTextStyle);
    this.youWonText.text.anchor.set(0.5, 0.5);
    this.youWonText.text.skew.set(-Math.PI, 0);
    const container =
      this.popupBackground.spine.slotContainers[this.popupBackground.spine.skeleton.findSlotIndex('you_won')];
    container!.removeChildren();
    container!.addChild(this.youWonText.getText());
  }

  private initSpinAndGrabText(): void {
    this.spinAndGrabText = new TextField(i18n.t('spinAndGrabButtonText'), 500, 100, spinAndGrabTextStyle);

    this.spinAndGrabText.text.anchor.set(0.5, 0.5);
    this.spinAndGrabText.text.skew.set(-Math.PI, 0);
    const container =
      this.popupBackground.spine.slotContainers[this.popupBackground.spine.skeleton.findSlotIndex('spin_grab')];
    container!.removeChildren();
    container!.addChild(this.spinAndGrabText.getText());
  }

  private initPressToContinueText(): void {
    this.pressToContinueText = new TextField(i18n.t('freeSpinsPressToContinue'), 500, 100, pressToContinueTextStyle);
    this.pressToContinueText.text.anchor.set(0.5, 0.5);
    this.pressToContinueText.text.skew.set(-Math.PI, 0);
    const container =
      this.popupBackground.spine.slotContainers[this.popupBackground.spine.skeleton.findSlotIndex('press_anywhere')];
    container!.removeChildren();
    container!.addChild(this.pressToContinueText.getText());
  }

  private initFadeInAnimation(): Animation {
    return new Tween({
      object: this.textContainer,
      property: TweenProperties.ALPHA,
      propertyBeginValue: 0,
      target: 1,
      duration: 300,
    });
  }

  private initFadeOutAnimation(): Animation {
    return new Tween({
      object: this.textContainer,
      property: TweenProperties.ALPHA,
      propertyBeginValue: 1,
      target: 0,
      duration: 300,
    });
  }

  private initBackGroundFadeInAnimation(): Animation {
    return new Tween({
      object: this.background,
      property: TweenProperties.ALPHA,
      propertyBeginValue: 0,
      target: 0.7,
      duration: 500,
    });
  }

  private initBackGroundFadeOutAnimation(): Animation {
    return new Tween({
      object: this.background,
      property: TweenProperties.ALPHA,
      propertyBeginValue: 0.7,
      target: 0,
      duration: 500,
    });
  }

  public override show(_props?: PopupProps): void {
    AudioApi.play({ type: ISongs.SG_EntrancePopup });
    delayedAction(mappedAudioSprites[ISongs.SG_EntrancePopup].duration, () => {
      AudioApi.play({ type: ISongs.BGM_SG_Loop });
      if (setIsDuringBigWinLoop()) {
        AudioApi.fadeOut(0, ISongs.BGM_SG_Loop);
      }
    });
    this.popupBackground.show();
    this.on('click', this.handleClose);
    this.on('touchstart', this.handleClose);
    eventManager.once(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER, this.handleClose);
    if (setIsContinueAutoSpinsAfterFeature()) {
      this.autoSkipDelay = setTimeout(this.handleClose, 1000);
    }
  }

  private handleClose = () => {
    if (!AudioApi.isInitialized) {
      eventManager.emit(EventTypes.ENABLE_SOUND_LOADER);
    }
    AudioApi.changeRestriction(
      false,
      [],
      () => setIsSoundLoading(true),
      () => {
        setIsShowSoundToast(false);
        handleChangeRestriction(Logic.the.controller.gameMode);
      },
    );

    clearTimeout(this.autoSkipDelay);
    eventManager.removeListener(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER, this.handleClose);
    this.removeListener('click', this.handleClose);
    this.removeListener('touchstart', this.handleClose);
    this.popupBackground.hide();
  };

  protected override resize(width: number, height: number): void {
    this.background.width = width;
    this.background.height = height;
    const isLandscape = width >= height;
    const factor = (isMobile.any && !isLandscape ? 2 : 1) * Math.min(height / 1080, width / 1920);
    this.popupBackground.scale.set(factor);
    this.position.set(width / 2, height / 2);
  }
}

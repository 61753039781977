import * as PIXI from 'pixi.js';
import { isMobile } from 'pixi.js';

import { GameMode } from '../../global.d';
import { ResourceTypes } from '../../resources.d';
import { ViewContainer } from '../components/ViewContainer';
import { SLOTS_CONTAINER_WIDTH } from '../config';

class Logo extends ViewContainer {
  public gameLogo: PIXI.Sprite;

  constructor() {
    super();
    this.gameLogo = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.gameLogo));

    this.addChild(this.gameLogo);
  }

  protected override onModeChange(settings: { mode: GameMode }): void {
    if (settings.mode === GameMode.FREE_SPINS || settings.mode === GameMode.SPIN_AND_GRAB) {
      this.gameLogo.visible = false;
    } else {
      this.gameLogo.visible = true;
    }
  }

  override resize(width: number, height: number): void {
    this.gameLogo.scale.set(1);
    this.gameLogo.x = SLOTS_CONTAINER_WIDTH / 2 - this.gameLogo.width / 2;
    this.gameLogo.y = -40;

    if (isMobile.any && width < height) {
      this.gameLogo.scale.set(1.255);
      this.gameLogo.x = SLOTS_CONTAINER_WIDTH / 2 - this.gameLogo.width / 2;
      this.gameLogo.y = -101;
    }
  }
}

export default Logo;

import { Sprite, Texture } from 'pixi.js';

import { GameMode } from '../../global.d';
import { ResourceTypes } from '../../resources.d';
import { ViewContainer } from '../components/ViewContainer';

class FrameStatic extends ViewContainer {
  public frame!: Sprite;

  constructor() {
    super();
    this.sortableChildren = true;
    this.init();
  }

  private init(): void {
    this.frame = new Sprite(Texture.from(ResourceTypes.frame));
    this.frame.y = 95;
    this.frame.x = -33;
    this.frame.zIndex = 2;
    this.addChild(this.frame);
  }

  protected override onModeChange(settings: { mode: GameMode }): void {
    switch (settings.mode) {
      case GameMode.BASE_GAME:
        this.handleBaseModeChanges();
        break;
      case GameMode.FREE_SPINS:
        this.handleFreeSpinsChanges();
        break;
      default:
        this.handleBaseModeChanges();
        break;
    }
  }

  public handleBaseModeChanges(): void {
    this.frame.texture = Texture.from(ResourceTypes.frame);
  }

  public handleFreeSpinsChanges(): void {
    this.frame.texture = Texture.from(ResourceTypes.frameFreeSpins);
  }
}

export default FrameStatic;

import { Sprite, Texture, isMobile } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import variables from '../../assets/styles/export.module.scss';
import { ISongs } from '../../config';
import { EventTypes, GameMode, ModalOpeningTypes } from '../../global.d';
import { setIsAutoSpins, setIsLeftHandMode, setIsModalOpeningInProgress } from '../../gql/cache';
import i18n from '../../i18next';
import { ResourceTypes } from '../../resources.d';
import { TextField } from '../components/TextField';
import { ViewContainer } from '../components/ViewContainer';
import {
  FEATURE_BTN_CONTAINER_POSITION_X,
  FEATURE_BTN_HEIGHT,
  GAME_CONTAINER_HEIGHT,
  GAME_CONTAINER_WIDTH,
  PopupTypes,
  buyFeatureClickTextStyle,
  buyFeatureDisableTextStyle,
  buyFeatureHoverTextStyle,
  buyFeatureTextStyle,
  eventManager,
} from '../config';
import { PopupController } from '../popups/PopupController';

class BuyFeatureBtn extends ViewContainer {
  private btn: Sprite;

  private text: TextField;

  isDisabled: boolean;

  resizedWidth: number;

  resizedHeight: number;

  isLeftHandMode: boolean;

  constructor() {
    super();
    this.x = -150;
    this.y = 300;
    this.isDisabled = false;
    this.text = this.initBuyFeatureText();
    this.btn = this.initBuyFeatureBtn();
    this.resizedWidth = 0;
    this.resizedHeight = 0;
    this.isLeftHandMode = setIsLeftHandMode();
    this.addChild(this.btn);

    eventManager.on(EventTypes.DISABLE_BUY_FEATURE_BTN, (disable: boolean) => {
      this.handleDisable(disable);
    });
    eventManager.on(EventTypes.SET_IS_LEFT_HAND_MODE, (isLhm: boolean) => {
      this.isLeftHandMode = isLhm;
      this.handlePosition();
    });
  }

  protected override onModeChange(settings: { mode: GameMode }): void {
    switch (settings.mode) {
      case GameMode.BASE_GAME:
        this.visible = true;
        break;
      case GameMode.FREE_SPINS:
        this.visible = false;
        break;
      case GameMode.SPIN_AND_GRAB:
        this.visible = false;
        break;
      default:
        this.visible = true;
        break;
    }
  }

  private initBuyFeatureBtn() {
    const btn = new Sprite(Texture.from(ResourceTypes.buyFeature));
    btn.anchor.set(0.5);
    btn.width = 185;
    btn.height = 185;
    btn.interactive = true;
    btn.addChild(this.text.getText());
    btn.on('click', () => this.onClick());
    btn.on('touchstart', () => this.onClick());

    btn.addListener('mouseover', (_e) => {
      if (!this.isDisabled) {
        AudioApi.play({ type: ISongs.SFX_UI_Hover });
        this.btn.texture = Texture.from(ResourceTypes.buyFeatureHover);

        this.text.text.style = buyFeatureHoverTextStyle;
      }
    });
    btn.addListener('mouseout', () => {
      if (!this.isDisabled) {
        this.btn.texture = Texture.from(ResourceTypes.buyFeature);

        this.text.text.style = buyFeatureTextStyle;
      }
    });
    btn.addListener('mousedown', () => {
      if (!this.isDisabled) {
        this.btn.texture = Texture.from(ResourceTypes.buyFeaturePressed);

        this.text.text.style = buyFeatureClickTextStyle;
      }
    });
    btn.addListener('mouseup', () => {
      if (!this.isDisabled) {
        this.btn.texture = Texture.from(ResourceTypes.buyFeature);

        this.text.text.style = buyFeatureTextStyle;
      }
    });
    return btn;
  }

  private initBuyFeatureText(): TextField {
    const text = new TextField(i18n.t<string>('buyFeatureBtn'), 95, 200, buyFeatureTextStyle);
    text.text.anchor.set(0.5, 0.5);
    text.text.position.set(this.width / 2, this.height / 2 - 5);

    return text;
  }

  private onClick(): void {
    if (!this.isDisabled && setIsModalOpeningInProgress() === ModalOpeningTypes.NONE && !setIsAutoSpins()) {
      setIsModalOpeningInProgress(ModalOpeningTypes.BUY_FEATURE_POPUP);
      AudioApi.play({ type: ISongs.BuyButton });
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, true);
      PopupController.the.openPopup(PopupTypes.BUY_FEATURE);

      setTimeout(() => setIsModalOpeningInProgress(ModalOpeningTypes.NONE), 100);
    }
  }

  private handleDisable(disable: boolean): void {
    if (this.isAutoSpinInProgress) return;

    this.isDisabled = disable;
    if (disable) {
      this.btn.texture = Texture.from(ResourceTypes.buyFeatureDisable);

      this.text.text.style = buyFeatureDisableTextStyle;
    } else {
      this.btn.texture = Texture.from(ResourceTypes.buyFeature);

      this.text.text.style = buyFeatureTextStyle;
    }
  }

  private get isAutoSpinInProgress(): boolean {
    return this.isDisabled && setIsAutoSpins();
  }

  protected override resize(width: number, height: number): void {
    this.resizedWidth = width;
    this.resizedHeight = height;
    this.handlePosition();
  }

  private handlePosition(): void {
    if (this.resizedWidth < this.resizedHeight && isMobile.any) {
      this.scale.set(1.3);
      this.x = this.isLeftHandMode ? GAME_CONTAINER_WIDTH - 140 : 130;
      this.y = GAME_CONTAINER_HEIGHT + FEATURE_BTN_HEIGHT + 93;
      return;
    }
    this.scale.set(1);
    if (
      this.resizedWidth >= parseInt(variables['breakpointTabletLandscapeMd'] as string, 10) &&
      this.resizedHeight <= parseInt(variables['breakpointTabletLandscapeXs'] as string, 10) &&
      isMobile.any
    ) {
      this.x = this.isLeftHandMode ? GAME_CONTAINER_WIDTH : FEATURE_BTN_CONTAINER_POSITION_X / 1.2;
    } else {
      this.x = this.isLeftHandMode && isMobile.any ? GAME_CONTAINER_WIDTH + 125 : -135;
    }
    this.y = this.isLeftHandMode ? 500 : 520;
  }
}

export default BuyFeatureBtn;

import type { ReelSet } from '../global';
import { REELS_AMOUNT, SLOTS_PER_REEL_AMOUNT } from '../slotMachine/config';
import type { Icon } from '../slotMachine/d';

const normalize = (coord: number, layout: string[]) => {
  return coord < 0 ? layout.length - (Math.abs(coord) % layout.length) : coord % layout.length;
};

export const getSpinResult = ({
  reelPositions,
  reelSet,
  icons,
}: {
  reelPositions: number[];
  reelSet: ReelSet;
  icons: Icon[];
}): Icon[] => {
  const cols = REELS_AMOUNT;
  const rows = SLOTS_PER_REEL_AMOUNT;
  const spinResult = [...(Array(cols * rows) as Icon[])].map((_, index) => {
    const row = Math.floor(index / cols);
    const column = index % cols;
    const layout = reelSet.layout[column as number];

    const initialCoord = reelPositions[column as number];
    const coord = (initialCoord as number) + row - 1;
    return (
      icons.find((icon) => icon.id === reelSet.layout[column as number]![normalize(coord, layout as string[])]) ||
      icons[0]
    );
  }) as Icon[];

  return spinResult;
};

import { Spine } from 'pixi-spine';
import * as PIXI from 'pixi.js';
import { Texture, isMobile } from 'pixi.js';

import { GameMode } from '../../global.d';
import { setBrokenGame } from '../../gql/cache';
import { ResourceTypes } from '../../resources.d';
import { ViewContainer } from '../components/ViewContainer';

class Background extends ViewContainer {
  private baseBg = Texture.from(ResourceTypes.slotBg);

  private baseBgPortrait = Texture.from(ResourceTypes.slotBgPortrait);

  private spinAndGrabBg = Texture.from(ResourceTypes.slotBgSpinAndGrab);

  private spinAndGrabBgPortrait = Texture.from(ResourceTypes.slotBgSpinAndGrabPortrait);

  private freeSpinsBg = Texture.from(ResourceTypes.slotBgFreeSpins);

  private freeSpinsBgPortrait = Texture.from(ResourceTypes.slotBgFreeSpinsPortrait);

  private bgSprite = new PIXI.Sprite();

  private ambientBaseAnimation: Spine | null = null;

  private appHeight!: number;

  private appWidth!: number;

  private gameMode = GameMode.BASE_GAME;

  constructor() {
    super();

    // set initial background
    if (setBrokenGame()) {
      this.bgSprite.texture = this.baseBg;
    } else {
      this.bgSprite.texture = this.baseBg;
    }
    this.bgSprite.anchor.set(0.5);
    this.addChild(this.bgSprite);
    this.initAnimation();
  }

  protected override onModeChange(settings: { mode: GameMode }): void {
    this.gameMode = settings.mode;
    this.changeBg();
  }

  private changeBg(): void {
    const isPortrait = isMobile.any && this.appHeight > this.appWidth;

    if (this.gameMode === GameMode.FREE_SPINS) {
      this.bgSprite.texture = isPortrait ? this.freeSpinsBgPortrait : this.freeSpinsBg;
      this.ambientBaseAnimation!.state.setAnimation(0, isPortrait ? 'free_spin_bg_portrait' : 'free_spin_bg', true);
    }
    if (this.gameMode === GameMode.BASE_GAME) {
      this.bgSprite.texture = isPortrait ? this.baseBgPortrait : this.baseBg;
      this.ambientBaseAnimation!.state.setAnimation(0, isPortrait ? 'main_bg_portrait' : 'main_bg', true);
    }
    if (this.gameMode === GameMode.SPIN_AND_GRAB) {
      this.bgSprite.texture = isPortrait ? this.spinAndGrabBgPortrait : this.spinAndGrabBg;
      this.ambientBaseAnimation!.state.setAnimation(
        0,
        isPortrait ? 'spin_and_grab_bg_portrait' : 'spin_and_grab_bg',
        true,
      );
    }
  }

  private initAnimation(): void {
    this.ambientBaseAnimation = new Spine(PIXI.Loader.shared.resources['ambient']!.spineData!);

    this.addChild(this.ambientBaseAnimation);
    this.ambientBaseAnimation.state.setAnimation(0, 'main_bg', true);
  }

  public override resize(width: number, height: number): void {
    this.x = width / 2;
    this.y = height / 2;
    this.appWidth = width;
    this.appHeight = height;
    this.changeBg();
    const bgAspectRatio = this.bgSprite.width / this.bgSprite.height;
    const aspectRatio = width / height;

    if (bgAspectRatio > aspectRatio) {
      this.scale.set(height / this.bgSprite.height);
    } else {
      this.scale.set(width / this.bgSprite.width);
    }
  }
}
export default Background;

import { isMobile } from 'mobile-device-detect';

import { setUserConfig } from '@phoenix7dev/utils-fe';

import type { GameMode, ISpinAndGrabRound, ModalOpeningTypes, UserBonus } from '../global';

import {
  setAutoSpinsAmount,
  setAutoSpinsLeft,
  setAutoSpinsStartBalance,
  setBetAmount,
  setCoinAmount,
  setCoinValue,
  setCurrentBonus,
  setCurrentFreeSpinsTotalWin,
  setCurrentSpinAndGrabRound,
  setFreeSpinsTotalWin,
  setGameHistory,
  setGameMode,
  setGrabAndSpinTotalWin,
  setIsAuthorized,
  setIsAutoSpins,
  setIsContinueAutoSpinsAfterFeature,
  setIsEnabledSpaceSpin,
  setIsFreeSpinsWin,
  setIsLeftHandMode,
  setIsMiniPayTable,
  setIsModalOpeningInProgress,
  setIsOpenAutoplayModal,
  setIsOpenBetSettingsModal,
  setIsOpenMenuModal,
  setIsShowSoundToast,
  setIsSlotBusy,
  setIsSoundOn,
  setIsSpinAndGrabWin,
  setIsSpinInProgress,
  setIsStopOnAnyWin,
  setIsStopOnBalanceDecrease,
  setIsStopOnBalanceIncrease,
  setIsStopOnFeatureWin,
  setIsStopOnWinExceeds,
  setIsSuspended,
  setIsTurboSpin,
  setProgress,
  setSkipIntroScreen,
  setSlotConfig,
  setSoundValue,
  setSpinAndGrabRoundsLeft,
  setStopOnBalanceDecrease,
  setStopOnBalanceIncrease,
  setStopOnWinExceeds,
  setStressful,
  setWinAmount,
} from './cache';
import type { DataGQl, ISlotConfig, IStressful } from './d';

const typePolicies = {
  Query: {
    fields: {
      isAuthorized: {
        read(): boolean {
          return setIsAuthorized();
        },
      },
      progress: {
        read(): { status: number; wasLoaded?: boolean } {
          return setProgress();
        },
      },
      isLeftHandMode: {
        read(_: unknown, { storeFieldName }: DataGQl): boolean {
          return setUserConfig('config', storeFieldName, setIsLeftHandMode());
        },
      },
      isSoundOn: {
        read(_: unknown, { storeFieldName }: DataGQl): boolean {
          return setUserConfig('config', storeFieldName, setIsSoundOn());
        },
      },
      isShowSoundToast: {
        read(): boolean {
          return setIsShowSoundToast();
        },
      },
      isShowSuspendedToast: {
        read(): boolean {
          return setIsSuspended();
        },
      },
      soundValue: {
        read(): number {
          return setSoundValue();
        },
      },
      isSpinInProgress: {
        read(): boolean {
          return setIsSpinInProgress();
        },
      },
      isFreeSpinsWin: {
        read(): boolean {
          return setIsFreeSpinsWin();
        },
      },
      setIsSpinAndGrabWin: {
        read(): boolean {
          return setIsSpinAndGrabWin();
        },
      },
      isSlotBusy: {
        read(): boolean {
          return setIsSlotBusy();
        },
      },
      isMobile: {
        read(): boolean {
          return isMobile;
        },
      },
      betAmount: {
        read(): number {
          return setBetAmount();
        },
      },
      currentFreeSpinsTotalWin: {
        read(): number {
          return setCurrentFreeSpinsTotalWin();
        },
      },
      winAmount: {
        read(): number {
          return setWinAmount();
        },
      },
      coinValue: {
        read(): number {
          return setCoinValue();
        },
      },
      coinAmount: {
        read(): number {
          return setCoinAmount();
        },
      },
      autoSpinsAmount: {
        read(): number {
          return setAutoSpinsAmount();
        },
      },
      isContinueAutoSpinsAfterFeature: {
        read(): boolean {
          return setIsContinueAutoSpinsAfterFeature();
        },
      },
      isStopOnAnyWin: {
        read(): boolean {
          return setIsStopOnAnyWin();
        },
      },
      isStopOnFeatureWin: {
        read(): boolean {
          return setIsStopOnFeatureWin();
        },
      },
      isStopOnWinExceeds: {
        read(): boolean {
          return setIsStopOnWinExceeds();
        },
      },
      isStopOnBalanceIncrease: {
        read(): boolean {
          return setIsStopOnBalanceIncrease();
        },
      },
      isStopOnBalanceDecrease: {
        read(): boolean {
          return setIsStopOnBalanceDecrease();
        },
      },
      isAutoSpins: {
        read(): boolean {
          return setIsAutoSpins();
        },
      },
      freeSpinsTotalWin: {
        read(): number {
          return setFreeSpinsTotalWin();
        },
      },
      grabAndSpinTotalWin: {
        read(): number {
          return setGrabAndSpinTotalWin();
        },
      },
      gameMode: {
        read(): GameMode {
          return setGameMode();
        },
      },
      currentBonus: {
        read(): UserBonus {
          return setCurrentBonus();
        },
      },
      currentSpinAndGrabRound: {
        read(): ISpinAndGrabRound {
          return setCurrentSpinAndGrabRound();
        },
      },
      setSpinAndGrabRoundsLeft: {
        read(): number {
          return setSpinAndGrabRoundsLeft();
        },
      },
      autoSpinsLeft: {
        read(): number {
          return setAutoSpinsLeft();
        },
      },
      stopOnWinExceeds: {
        read(): number {
          return setStopOnWinExceeds();
        },
      },
      stopOnBalanceIncrease: {
        read(): number {
          return setStopOnBalanceIncrease();
        },
      },
      stopOnBalanceDecrease: {
        read(): number {
          return setStopOnBalanceDecrease();
        },
      },
      autoSpinsStartBalance: {
        read(): number {
          return setAutoSpinsStartBalance();
        },
      },
      slotConfig: {
        read(): ISlotConfig {
          return setSlotConfig();
        },
      },
      slotHistory: {
        read(): boolean[] {
          return setGameHistory();
        },
      },
      isTurboSpin: {
        read(_: unknown, { storeFieldName }: DataGQl): boolean {
          return setUserConfig('config', storeFieldName, setIsTurboSpin());
        },
      },
      isMiniPayTable: {
        read(_: unknown, { storeFieldName }: DataGQl): boolean {
          return setUserConfig('config', storeFieldName, setIsMiniPayTable());
        },
      },
      isEnabledSpaceSpin: {
        read(_: unknown, { storeFieldName }: DataGQl): boolean {
          return setUserConfig('config', storeFieldName, setIsEnabledSpaceSpin());
        },
      },
      isSkipIntroScreen: {
        read(_: unknown, { storeFieldName }: DataGQl): boolean {
          return setUserConfig('config', storeFieldName, setSkipIntroScreen());
        },
      },
      stressful: {
        read(): IStressful {
          return setStressful();
        },
      },
      bets: {
        keyArgs: false as const,
        merge: false,
      },
      isOpenMenuModal: {
        read(): { isOpen: boolean; type: string } {
          return setIsOpenMenuModal();
        },
      },
      isOpenBetSettingsModal: {
        read(): boolean {
          return setIsOpenBetSettingsModal();
        },
      },
      isOpenAutoplayModal: {
        read(): boolean {
          return setIsOpenAutoplayModal();
        },
      },
      isModalOpeningInProgress: {
        read(): ModalOpeningTypes {
          return setIsModalOpeningInProgress();
        },
      },
    },
  },
};

export default typePolicies;

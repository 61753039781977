import { ResourceTypes } from '../resources.d';
import Resources from '../utils/resources';

import { SlotId } from './config';

export const MAPPED_SYMBOLS: Record<SlotId, { default: ResourceTypes; freespin?: ResourceTypes }> = {
  [SlotId.A]: { default: ResourceTypes.a },
  [SlotId.C]: { default: ResourceTypes.c },
  [SlotId.E]: { default: ResourceTypes.e },
  [SlotId.J]: { default: ResourceTypes.j },
  [SlotId.K]: { default: ResourceTypes.k },
  [SlotId.M]: { default: ResourceTypes.m },
  [SlotId.Q]: { default: ResourceTypes.q },
  [SlotId.X]: { default: ResourceTypes.x },
  [SlotId.SC1]: { default: ResourceTypes.sc1 },
  [SlotId.WL]: { default: ResourceTypes.wl },
  [SlotId.HP1]: { default: ResourceTypes.hp1 },
  [SlotId.HP2]: { default: ResourceTypes.hp2 },
  [SlotId.HP3]: { default: ResourceTypes.hp3 },
  [SlotId.HP4]: { default: ResourceTypes.hp4 },
};

export const MAPPED_BLURRED_SYMBOLS = {
  [SlotId.A]: { default: ResourceTypes.aMotion },
  [SlotId.C]: { default: ResourceTypes.cMotion },
  [SlotId.E]: { default: ResourceTypes.eMotion },
  [SlotId.J]: { default: ResourceTypes.jMotion },
  [SlotId.K]: { default: ResourceTypes.kMotion },
  [SlotId.M]: { default: ResourceTypes.mMotion },
  [SlotId.Q]: { default: ResourceTypes.qMotion },
  [SlotId.X]: { default: ResourceTypes.xMotion },
  [SlotId.SC1]: { default: ResourceTypes.sc1Motion },
  [SlotId.WL]: { default: ResourceTypes.wlMotion },
  [SlotId.HP1]: { default: ResourceTypes.hp1Motion },
  [SlotId.HP2]: { default: ResourceTypes.hp2Motion },
  [SlotId.HP3]: { default: ResourceTypes.hp3Motion },
  [SlotId.HP4]: { default: ResourceTypes.hp4Motion },
};

export enum SymbolAnimationType {
  DEFAULT,
  SPRITE,
  SPINE,
}

export const MAPPED_SYMBOLS_WIN_ANIMATIONS: {
  [key in SlotId]: {
    type: SymbolAnimationType;
    src?: string;
    animation?: string;
  };
} = {
  [SlotId.M]: {
    type: SymbolAnimationType.SPINE,
    src: 'money_symbol',
    animation: 'money_symbol_win',
  },
  [SlotId.C]: {
    type: SymbolAnimationType.SPINE,
    src: 'collect_symbol',
    animation: 'collect_symbol_win',
  },
  [SlotId.E]: {
    type: SymbolAnimationType.SPINE,
    src: 'money_symbol',
    animation: 'money_symbol_win',
  },
  [SlotId.SC1]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'scatter_win',
  },
  [SlotId.WL]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'wild_win',
  },
  [SlotId.HP1]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'hp_1_win',
  },
  [SlotId.HP2]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'hp_2_win',
  },
  [SlotId.HP3]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'hp_3_win',
  },
  [SlotId.HP4]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'hp_4_win',
  },
  [SlotId.A]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'a',
  },
  [SlotId.K]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'k',
  },
  [SlotId.Q]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'q',
  },
  [SlotId.J]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'j',
  },
  [SlotId.X]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: '10',
  },
};

export const MAPPED_SYMBOLS_LAND_ANIMATIONS: {
  [key in SlotId]?: {
    type: SymbolAnimationType;
    src?: string;
    animation?: string;
  };
} = {
  [SlotId.M]: {
    type: SymbolAnimationType.SPINE,
    src: 'money_symbol',
    animation: 'money_symbol_land',
  },
  [SlotId.C]: {
    type: SymbolAnimationType.SPINE,
    src: 'collect_symbol',
    animation: 'collect_symbol_land',
  },
  [SlotId.SC1]: {
    type: SymbolAnimationType.SPINE,
    src: 'symbols',
    animation: 'scatter_land',
  },
};
export const LOADER_MAPPED_SYMBOLS = [
  { name: ResourceTypes.a, src: Resources.getSource(ResourceTypes.a) },
  {
    name: ResourceTypes.c,
    src: Resources.getSource(ResourceTypes.c),
  },
  {
    name: ResourceTypes.e,
    src: Resources.getSource(ResourceTypes.e),
  },
  { name: ResourceTypes.j, src: Resources.getSource(ResourceTypes.j) },
  {
    name: ResourceTypes.k,
    src: Resources.getSource(ResourceTypes.k),
  },
  {
    name: ResourceTypes.m,
    src: Resources.getSource(ResourceTypes.m),
  },
  {
    name: ResourceTypes.q,
    src: Resources.getSource(ResourceTypes.q),
  },
  {
    name: ResourceTypes.x,
    src: Resources.getSource(ResourceTypes.x),
  },
  {
    name: ResourceTypes.sc1,
    src: Resources.getSource(ResourceTypes.sc1),
  },
  {
    name: ResourceTypes.wl,
    src: Resources.getSource(ResourceTypes.wl),
  },
  {
    name: ResourceTypes.hp1,
    src: Resources.getSource(ResourceTypes.hp1),
  },
  {
    name: ResourceTypes.hp2,
    src: Resources.getSource(ResourceTypes.hp2),
  },
  {
    name: ResourceTypes.hp3,
    src: Resources.getSource(ResourceTypes.hp3),
  },
  {
    name: ResourceTypes.hp4,
    src: Resources.getSource(ResourceTypes.hp4),
  },

  {
    name: ResourceTypes.aMotion,
    src: Resources.getSource(ResourceTypes.aMotion),
  },
  {
    name: ResourceTypes.cMotion,
    src: Resources.getSource(ResourceTypes.cMotion),
  },
  {
    name: ResourceTypes.eMotion,
    src: Resources.getSource(ResourceTypes.eMotion),
  },
  {
    name: ResourceTypes.jMotion,
    src: Resources.getSource(ResourceTypes.jMotion),
  },
  {
    name: ResourceTypes.kMotion,
    src: Resources.getSource(ResourceTypes.kMotion),
  },
  {
    name: ResourceTypes.mMotion,
    src: Resources.getSource(ResourceTypes.mMotion),
  },
  {
    name: ResourceTypes.qMotion,
    src: Resources.getSource(ResourceTypes.qMotion),
  },
  {
    name: ResourceTypes.xMotion,
    src: Resources.getSource(ResourceTypes.xMotion),
  },
  {
    name: ResourceTypes.sc1Motion,
    src: Resources.getSource(ResourceTypes.sc1Motion),
  },
  {
    name: ResourceTypes.wlMotion,
    src: Resources.getSource(ResourceTypes.wlMotion),
  },
  {
    name: ResourceTypes.hp1Motion,
    src: Resources.getSource(ResourceTypes.hp1Motion),
  },
  {
    name: ResourceTypes.hp2Motion,
    src: Resources.getSource(ResourceTypes.hp2Motion),
  },
  {
    name: ResourceTypes.hp3Motion,
    src: Resources.getSource(ResourceTypes.hp3Motion),
  },
  {
    name: ResourceTypes.hp4Motion,
    src: Resources.getSource(ResourceTypes.hp4Motion),
  },
];

export const LOADER_TEXTURES = [
  { name: ResourceTypes.frame, src: Resources.getSource(ResourceTypes.frame) },
  {
    name: ResourceTypes.reelBackground,
    src: Resources.getSource(ResourceTypes.reelBackground),
  },
  {
    name: ResourceTypes.frameFreeSpins,
    src: Resources.getSource(ResourceTypes.frameFreeSpins),
  },
  {
    name: ResourceTypes.frameSpinAndGrab,
    src: Resources.getSource(ResourceTypes.frameSpinAndGrab),
  },
  {
    name: ResourceTypes.freeSpinsDefault,
    src: Resources.getSource(ResourceTypes.freeSpinsDefault),
  },
  {
    name: ResourceTypes.freeSpinsPressed,
    src: Resources.getSource(ResourceTypes.freeSpinsPressed),
  },
  {
    name: ResourceTypes.freeSpinsHover,
    src: Resources.getSource(ResourceTypes.freeSpinsHover),
  },
  {
    name: ResourceTypes.freeSpinsDisabled,
    src: Resources.getSource(ResourceTypes.freeSpinsDisabled),
  },
  {
    name: 'collect_symbol',
    src: '/animations/symbols/collect_symbol.json',
  },
  {
    name: 'money_symbol',
    src: '/animations/symbols/money_symbol.json',
  },
  {
    name: 'symbols',
    src: '/animations/symbols/symbols.json',
  },
  {
    name: 'bigWinMessages',
    src: '/animations/winMessages/special_messages.json',
  },
  {
    name: 'popups',
    src: '/animations/popups/pop_ups.json',
  },
  {
    name: 'buttonsSprite',
    src: '/images/buttons/buttons.json',
  },
  {
    name: 'multipliersSprite',
    src: '/images/multipliers/multipliers.json',
  },
  {
    name: 'infoBtnSprite',
    src: '/images/infoBtn/infomation.json',
  },
  {
    name: 'loaderSprite',
    src: '/images/loader/loader.json',
  },
  {
    name: 'ambient',
    src: '/animations/ambient/ambient.json',
  },
  {
    name: 'reelCover',
    src: '/animations/reelCover/reel_cover.json',
  },
  {
    name: ResourceTypes.slotBg,
    src: Resources.getSource(ResourceTypes.slotBg),
  },
  {
    name: ResourceTypes.slotBgFreeSpins,
    src: Resources.getSource(ResourceTypes.slotBgFreeSpins),
  },
  {
    name: ResourceTypes.slotBgSpinAndGrab,
    src: Resources.getSource(ResourceTypes.slotBgSpinAndGrab),
  },
  {
    name: ResourceTypes.slotBgPortrait,
    src: Resources.getSource(ResourceTypes.slotBgPortrait),
  },
  {
    name: ResourceTypes.slotBgFreeSpinsPortrait,
    src: Resources.getSource(ResourceTypes.slotBgFreeSpinsPortrait),
  },
  {
    name: ResourceTypes.slotBgSpinAndGrabPortrait,
    src: Resources.getSource(ResourceTypes.slotBgSpinAndGrabPortrait),
  },
  {
    name: ResourceTypes.reel5Background,
    src: Resources.getSource(ResourceTypes.reel5Background),
  },
  {
    name: ResourceTypes.counterFreeSpins,
    src: Resources.getSource(ResourceTypes.counterFreeSpins),
  },
  {
    name: ResourceTypes.introBg,
    src: Resources.getSource(ResourceTypes.introBg),
  },
  {
    name: ResourceTypes.introReel1,
    src: Resources.getSource(ResourceTypes.introReel1),
  },
  {
    name: ResourceTypes.introReel2,
    src: Resources.getSource(ResourceTypes.introReel2),
  },
  {
    name: ResourceTypes.introReel3,
    src: Resources.getSource(ResourceTypes.introReel3),
  },
  {
    name: ResourceTypes.buttonOk,
    src: Resources.getSource(ResourceTypes.buttonOk),
  },
  {
    name: ResourceTypes.buyFeatureMinusBtn,
    src: Resources.getSource(ResourceTypes.buyFeatureMinusBtn),
  },
  {
    name: ResourceTypes.buyFeatureMinusBtnHover,
    src: Resources.getSource(ResourceTypes.buyFeatureMinusBtnHover),
  },
  {
    name: ResourceTypes.buyFeatureMinusBtnPressed,
    src: Resources.getSource(ResourceTypes.buyFeatureMinusBtnPressed),
  },
  {
    name: ResourceTypes.buyFeatureMinusBtnDisable,
    src: Resources.getSource(ResourceTypes.buyFeatureMinusBtnDisable),
  },
  {
    name: ResourceTypes.buyFeaturePlusBtn,
    src: Resources.getSource(ResourceTypes.buyFeaturePlusBtn),
  },
  {
    name: ResourceTypes.buyFeaturePlusBtnHover,
    src: Resources.getSource(ResourceTypes.buyFeaturePlusBtnHover),
  },
  {
    name: ResourceTypes.buyFeaturePlusBtnPressed,
    src: Resources.getSource(ResourceTypes.buyFeaturePlusBtnPressed),
  },
  {
    name: ResourceTypes.buyFeaturePlusBtnDisable,
    src: Resources.getSource(ResourceTypes.buyFeaturePlusBtnDisable),
  },
  {
    name: ResourceTypes.buyFeatureOkBtn,
    src: Resources.getSource(ResourceTypes.buyFeatureOkBtn),
  },
  {
    name: ResourceTypes.buyFeatureOkBtnHover,
    src: Resources.getSource(ResourceTypes.buyFeatureOkBtnHover),
  },
  {
    name: ResourceTypes.buyFeatureOkBtnPressed,
    src: Resources.getSource(ResourceTypes.buyFeatureOkBtnPressed),
  },
  {
    name: ResourceTypes.buyFeatureOkBtnDisable,
    src: Resources.getSource(ResourceTypes.buyFeatureOkBtnDisable),
  },
  {
    name: ResourceTypes.buyButtonDefault,
    src: Resources.getSource(ResourceTypes.buyButtonDefault),
  },
  {
    name: ResourceTypes.buyButtonHover,
    src: Resources.getSource(ResourceTypes.buyButtonHover),
  },
  {
    name: ResourceTypes.buyButtonDisabled,
    src: Resources.getSource(ResourceTypes.buyButtonDisabled),
  },
  {
    name: ResourceTypes.buyButtonPressed,
    src: Resources.getSource(ResourceTypes.buyButtonPressed),
  },
  {
    name: ResourceTypes.buyFeatureXBtn,
    src: Resources.getSource(ResourceTypes.buyFeatureXBtn),
  },
  {
    name: ResourceTypes.buyFeatureXBtnPressed,
    src: Resources.getSource(ResourceTypes.buyFeatureXBtnPressed),
  },
  {
    name: ResourceTypes.buyFeatureXBtnDisabled,
    src: Resources.getSource(ResourceTypes.buyFeatureXBtnDisabled),
  },
  {
    name: ResourceTypes.buyFeatureXBtnHover,
    src: Resources.getSource(ResourceTypes.buyFeatureXBtnHover),
  },
  {
    name: ResourceTypes.buyFeaturePopup,
    src: Resources.getSource(ResourceTypes.buyFeaturePopup),
  },
  {
    name: ResourceTypes.buyFeature,
    src: Resources.getSource(ResourceTypes.buyFeature),
  },
  {
    name: ResourceTypes.buyFeatureDisable,
    src: Resources.getSource(ResourceTypes.buyFeatureDisable),
  },
  {
    name: ResourceTypes.buyFeatureHover,
    src: Resources.getSource(ResourceTypes.buyFeatureHover),
  },
  {
    name: ResourceTypes.buyFeaturePressed,
    src: Resources.getSource(ResourceTypes.buyFeaturePressed),
  },
  {
    name: ResourceTypes.subTitleBackplate,
    src: Resources.getSource(ResourceTypes.subTitleBackplate),
  },
  {
    name: ResourceTypes.buyFeaturePopupRage,
    src: Resources.getSource(ResourceTypes.buyFeaturePopupRage),
  },
  {
    name: ResourceTypes.backdrop,
    src: Resources.getSource(ResourceTypes.backdrop),
  },
  {
    name: ResourceTypes.buyFeaturePopupConfirm,
    src: Resources.getSource(ResourceTypes.buyFeaturePopupConfirm),
  },
  {
    name: ResourceTypes.buyFeaturePopupBackplate,
    src: Resources.getSource(ResourceTypes.buyFeaturePopupBackplate),
  },
  {
    name: ResourceTypes.spinAndGrabRespins,
    src: Resources.getSource(ResourceTypes.spinAndGrabRespins),
  },
  {
    name: ResourceTypes.gameLogo,
    src: Resources.getSource(ResourceTypes.gameLogo),
  },
  {
    name: 'paylines',
    src: '/animations/paylines/line.json',
    isSpine: true,
    spineImages: ['line.png'],
  },
  {
    name: 'anticipation',
    src: '/animations/anticipation/anticipation.json',
    isSpine: true,
    spineImages: ['anticipation.jpg'],
  },
];

import { useReactiveVar } from '@apollo/client';
import type React from 'react';
import { useTranslation } from 'react-i18next';

import {
  infoCollectFeatureDescs,
  infoFeatureBuy,
  infoFreeSpinsFeatureDescs,
  infoSpinAndGrabFeatureDescs,
} from '../../config/gameRules';
import { setSlotConfig } from '../../gql/cache';
import type { ISlotConfig } from '../../gql/d';
import { ResourceTypes } from '../../resources.d';
import Resources from '../../utils/resources';

import styles from './info.module.scss';

const CollectFeature: React.VFC = () => {
  const { t } = useTranslation();

  return (
    <section className={styles['feature']}>
      <h1 className={styles['title']}>{t('infoCollectFeatureTitle')}</h1>
      <div className={`${styles['p']} ${styles['left']}`}>
        {infoCollectFeatureDescs.map((keyName) => (
          <div key={keyName} dangerouslySetInnerHTML={{ __html: t(keyName) }} />
        ))}
      </div>
    </section>
  );
};

const FreeSpinsFeature: React.VFC = () => {
  const { t } = useTranslation();
  return (
    <section className={styles['feature']}>
      <h1 className={styles['title']}>{t('infoFreeSpinsFeatureTitle')}</h1>
      <div className={`${styles['p']} ${styles['left']}`}>
        {infoFreeSpinsFeatureDescs.map((keyName) => (
          <div key={keyName} dangerouslySetInnerHTML={{ __html: t(keyName) }} />
        ))}
      </div>
    </section>
  );
};

const SpinAndGrabFeature: React.VFC = () => {
  const { t } = useTranslation();
  return (
    <section className={styles['feature']}>
      <h1 className={styles['title']}>{t('infoSpinAndGrabFeatureTitle')}</h1>
      <div className={`${styles['p']} ${styles['left']}`}>
        {infoSpinAndGrabFeatureDescs.map((keyName) => (
          <div key={keyName} dangerouslySetInnerHTML={{ __html: t(keyName) }} />
        ))}
      </div>
    </section>
  );
};

const BuyFeatureFunction: React.VFC = () => {
  const { t } = useTranslation();
  if (!setSlotConfig().isBuyFeatureEnabled) {
    return null;
  }
  return (
    <section className={styles['feature']}>
      <h1 className={styles['title']}>{t('infoFeatureBuyTitle')}</h1>
      <div className={`${styles['buyfeaturecontainer']}`}>
        <img src={Resources.getSource(ResourceTypes.buyFeature)} alt={t('infoFeatureBuyTitle')} />
        <p className={`${styles['buyfeaturetext']}`} dangerouslySetInnerHTML={{ __html: t('buyFeatureBtn') }} />
      </div>
      <div className={`${styles['p']} ${styles['left']}`}>
        {infoFeatureBuy.map((keyName) => (
          <div key={keyName} dangerouslySetInnerHTML={{ __html: t(keyName) }} />
        ))}
      </div>
    </section>
  );
};

const Features: React.VFC = () => {
  const { isBuyFeatureEnabled } = useReactiveVar<ISlotConfig>(setSlotConfig);

  return (
    <div className={styles['gameRules']}>
      <CollectFeature />
      <FreeSpinsFeature />
      <SpinAndGrabFeature />
      {isBuyFeatureEnabled ? <BuyFeatureFunction /> : null}
    </div>
  );
};

export default Features;

import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import React, { useCallback, useEffect } from 'react';

import AudioApi from '@phoenix7dev/audio-api';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { ISongs, config } from '../../config';
import { EventTypes, GameMode, ISettledBet } from '../../global.d';
import {
  setAutoSpinsAmount,
  setAutoSpinsLeft,
  setAutoSpinsStartBalance,
  setBetResult,
  setBetResultDuration,
  setCoinAmount,
  setCoinValue,
  setCurrency,
  setCurrentBonus,
  setCurrentBonusId,
  setCurrentIsTurboSpin,
  setIsAutoSpins,
  setIsContinueAutoSpinsAfterFeature,
  setIsFreeSpinsWin,
  setIsOpenAutoplayModal,
  setIsOpenBetSettingsModal,
  setIsPopupOpened,
  setIsRevokeThrowingError,
  setIsShowSoundToast,
  setIsSlotBusy,
  setIsSoundLoading,
  setIsSpinAndGrabWin,
  setIsSpinInProgress,
  setIsStopOnAnyWin,
  setIsStopOnBalanceDecrease,
  setIsStopOnBalanceIncrease,
  setIsStopOnFeatureWin,
  setIsStopOnWinExceeds,
  setIsTimeoutErrorMessage,
  setIsTurboSpin,
  setLastRegularWinAmount,
  setSlotConfig,
  setStopOnBalanceDecrease,
  setStopOnBalanceIncrease,
  setStopOnWinExceeds,
  setUserBalance,
  setWinAmount,
} from '../../gql/cache';
import type { IConfig, ISlotConfig, IStressful } from '../../gql/d';
import { placeBetGql } from '../../gql/mutation';
import {
  getAutoSpinsGql,
  getBetAmountGql,
  getSlotLoadProgressInfoGql,
  isStoppedGql,
  slotConfigGql,
  stressfulGql,
} from '../../gql/query';
import { Logic } from '../../logic';
import { States } from '../../logic/config';
import { PopupTypes, eventManager } from '../../slotMachine/config';
import { PopupController } from '../../slotMachine/popups/PopupController';
import { canPressSpin, getBGMSoundByGameMode, handleChangeRestriction, showCurrency } from '../../utils';

import type { IPlaceBetInput } from './d';

let timeout: ReturnType<typeof setTimeout>;

const Spin: React.FC = () => {
  const { data } = useQuery<IConfig>(slotConfigGql);
  const { isTurboSpin } = data!;
  const { data: dataBet } = useQuery<{ betAmount: number }>(getBetAmountGql);
  const { data: stressful } = useQuery<{ stressful: IStressful }>(stressfulGql);
  const { id: slotId, lineSets } = useReactiveVar<ISlotConfig>(setSlotConfig);
  const lineSet = lineSets[0];
  const isFreeSpinsWin = useReactiveVar<boolean>(setIsFreeSpinsWin);
  const isSpinAndGrabWin = useReactiveVar<boolean>(setIsSpinAndGrabWin);
  const userBalance = useReactiveVar(setUserBalance);
  const { data: dataProgress } = useQuery<{
    progress: { status: number; wasLoaded?: boolean };
  }>(getSlotLoadProgressInfoGql);
  const { data: dataSlotStopped } = useQuery<{ isSlotStopped: boolean }>(isStoppedGql);

  const balanceAmount = userBalance?.balance.amount || 0;

  const { progress } = dataProgress!;

  const { data: autoSpins } = useQuery<{
    isAutoSpins: boolean;
    autoSpinsLeft: number;
  }>(getAutoSpinsGql);
  const { isAutoSpins } = autoSpins!;

  const [fnGet, { client }] = useMutation<{ placeBet: ISettledBet }, { input: IPlaceBetInput }>(placeBetGql, {
    onError() {
      eventManager.emit('placeBetCompleted');
    },

    async onCompleted({ placeBet }) {
      eventManager.emit('placeBetCompleted');

      if (Logic.the.controller.gameMode === GameMode.SPIN_AND_GRAB) {
        AudioApi.fadeOut(1000, getBGMSoundByGameMode(Logic.the.controller.gameMode));
        PopupController.the.openPopup(PopupTypes.SPIN_AND_GRAB_END);
        eventManager.once(EventTypes.EXIT_SPIN_AND_GRAB_MODE, () => {
          setBetResult(placeBet);
          PopupController.the.closeCurrentPopup();
          Logic.the.changeState(States.TRANSITION);
          Logic.the.changeGameMode(GameMode.BASE_GAME);
        });
        return;
      }
      setCurrentIsTurboSpin(setIsTurboSpin());
      Logic.the.controller.setResult(placeBet);
      if (Logic.the.isStopped()) {
        Logic.the.quickStop();
      }
      setBetResultDuration(performance.now() - setBetResultDuration());
    },
  });

  const resetPopupsStateToClosed = () => {
    setIsOpenBetSettingsModal(false);
    setIsOpenAutoplayModal(false);
  };

  const onSpin = useCallback(
    (isTurboSpin?: boolean) => {
      if (setIsTimeoutErrorMessage() || setIsRevokeThrowingError()) return;
      setBetResultDuration(performance.now());

      clearTimeout(timeout);
      if (AudioApi.isRestricted) {
        AudioApi.changeRestriction(
          false,
          [],
          () => setIsSoundLoading(true),
          () => {
            handleChangeRestriction(Logic.the.controller.gameMode);
            setIsShowSoundToast(false);
          },
        );
      }
      if (Logic.the.isReadyToStop()) {
        Logic.the.quickStop();
        client.writeQuery({
          query: isStoppedGql,
          data: {
            isSlotStopped: true,
          },
        });
        return;
      }
      eventManager.emit(EventTypes.SKIP_WIN_COUNT_UP_ANIMATION);
      if (Logic.the.isReadyToSkip()) {
        Logic.the.skipWinAnimation();
        return;
      }
      if (Logic.the.isReadyToSpin()) {
        resetPopupsStateToClosed();
        setCurrentIsTurboSpin(isTurboSpin);
        setWinAmount(0);
        setLastRegularWinAmount(0);
        Logic.the.spin();
        eventManager.emit(
          EventTypes.UPDATE_WIN_VALUE,
          formatNumber({ currency: setCurrency(), value: 0, showCurrency: showCurrency(setCurrency()) }),
        );
        if (setIsAutoSpins()) setAutoSpinsLeft(setAutoSpinsLeft() - 1);
        client.writeQuery({
          query: isStoppedGql,
          data: {
            isSlotStopped: false,
          },
        });
        fnGet({
          variables: {
            input: {
              slotId,
              coinAmount: setCoinAmount(),
              coinValue: setCoinValue(),
              lineSetId: lineSet!.id,
              userBonusId: setCurrentBonusId() as string,
            },
          },
        });
        setCurrentBonusId(undefined);
        setIsSpinInProgress(true);
        setIsSlotBusy(true);
        AudioApi.stop({ type: ISongs.SFX_UI_Close });
        AudioApi.play({ type: ISongs.SFX_UI_SpinStart });
        if (AudioApi.isRestricted) {
          handleChangeRestriction(Logic.the.controller.gameMode);
        }
      }
    },
    [dataBet?.betAmount, fnGet, lineSet, lineSet, slotId],
  );
  useEffect(() => {
    const freeSpin = () => {
      if (setIsTimeoutErrorMessage() || setIsRevokeThrowingError()) return;
      setBetResultDuration(performance.now());
      setCurrentIsTurboSpin(setIsTurboSpin());
      clearTimeout(timeout);
      Logic.the.spin();
      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: false,
        },
      });
      fnGet({
        variables: {
          input: {
            slotId,
            coinAmount: setCoinAmount(),
            coinValue: setCoinValue(),
            lineSetId: lineSet!.id,
            userBonusId: setCurrentBonus().id,
          },
        },
      });
      setIsSpinInProgress(true);
      setIsSlotBusy(true);
      AudioApi.play({ type: ISongs.SFX_UI_SpinStart });
    };

    const spinAndGrabSpin = () => {
      setBetResultDuration(performance.now());
      fnGet({
        variables: {
          input: {
            slotId,
            coinAmount: setCoinAmount(),
            coinValue: setCoinValue(),
            lineSetId: lineSet!.id,
            userBonusId: setCurrentBonus().id,
          },
        },
      });
    };

    eventManager.on(EventTypes.NEXT_FREE_SPINS_ROUND, freeSpin);
    eventManager.on(EventTypes.FINISH_SPIN_AND_GRAB, spinAndGrabSpin);
    return () => {
      eventManager.removeListener(EventTypes.NEXT_FREE_SPINS_ROUND, freeSpin);
      eventManager.removeListener(EventTypes.FINISH_SPIN_AND_GRAB, spinAndGrabSpin);
    };
  }, [onSpin, isTurboSpin]);

  const checkAutoSpinSettings = useCallback(() => {
    if (setIsAutoSpins() && !stressful?.stressful.show) {
      const autoSpinsLeft = setAutoSpinsLeft() <= 0;
      const bonus = setIsStopOnFeatureWin() && setCurrentBonus().isActive;
      const stopOnWin = setIsStopOnAnyWin() && (setLastRegularWinAmount() > 0 || setCurrentBonus().isActive);

      const stopOnWinExceeds = setIsStopOnWinExceeds() && setLastRegularWinAmount() >= setStopOnWinExceeds();

      const balanceIncrease =
        setIsStopOnBalanceIncrease() &&
        balanceAmount &&
        setStopOnBalanceIncrease() * setCoinValue() <= balanceAmount - setAutoSpinsStartBalance();

      const balanceDecrease =
        setIsStopOnBalanceDecrease() &&
        balanceAmount &&
        setStopOnBalanceDecrease() * setCoinValue() <= setAutoSpinsStartBalance() - balanceAmount;
      if (autoSpinsLeft || bonus || stopOnWin || stopOnWinExceeds || balanceIncrease || balanceDecrease) {
        setIsAutoSpins(false);
        if (!setIsSlotBusy()) {
          eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
        }
      } else {
        onSpin(isTurboSpin);
      }
    }
  }, [balanceAmount, onSpin, isTurboSpin, stressful?.stressful]);

  useEffect(() => {
    if (isAutoSpins && (setIsFreeSpinsWin() || setIsSpinAndGrabWin())) {
      if (setIsStopOnFeatureWin()) {
        setIsContinueAutoSpinsAfterFeature(false);
        setAutoSpinsLeft(0);
      } else {
        setIsContinueAutoSpinsAfterFeature(true);
      }
      setIsAutoSpins(false);
    }
  }, [isFreeSpinsWin, isSpinAndGrabWin, setIsContinueAutoSpinsAfterFeature()]);

  const onSpinButtonClick = useCallback(() => {
    if (
      (Logic.the.controller.gameMode === GameMode.BASE_GAME ||
        Logic.the.controller.gameMode === GameMode.BUY_FEATURE) &&
      (setIsFreeSpinsWin() || setIsSpinAndGrabWin())
    ) {
      return;
    }
    if (isAutoSpins) {
      setAutoSpinsLeft(0);
      setIsAutoSpins(false);
      if (!setIsSlotBusy()) {
        eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
      }
    } else {
      onSpin(isTurboSpin);
    }
  }, [isAutoSpins, isTurboSpin, onSpin]);

  const useHandleSpaceSpin = useCallback(
    (e: KeyboardEvent) => {
      if (e.keyCode === 32 && !stressful?.stressful.show) {
        e.preventDefault();
        e.stopPropagation();

        if (data?.isEnabledSpaceSpin) {
          eventManager.emit(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER);
        }

        if (
          !canPressSpin({
            gameMode: Logic.the.controller.gameMode,
            isFreeSpinsWin: setIsFreeSpinsWin(),
            bonusCurrentRound: setCurrentBonus().currentRound,
            isSpinInProgress: setIsSpinInProgress(),
            isSlotBusy: setIsSlotBusy(),
            isSlotStopped: dataSlotStopped?.isSlotStopped ?? false,
            isPopupOpened: setIsPopupOpened(),
          })
        ) {
          return;
        }

        if (data?.isEnabledSpaceSpin && isAutoSpins) {
          checkAutoSpinSettings();
          return;
        }
        if (data?.isEnabledSpaceSpin) {
          onSpin(isTurboSpin);
        }
      }
    },
    [
      isAutoSpins,
      dataSlotStopped?.isSlotStopped,
      data?.isEnabledSpaceSpin,
      progress?.wasLoaded,
      checkAutoSpinSettings,
      onSpin,
      isTurboSpin,
      stressful?.stressful,
    ],
  );

  useEffect(() => {
    window.addEventListener('keydown', useHandleSpaceSpin);
    return () => window.removeEventListener('keydown', useHandleSpaceSpin);
  }, [useHandleSpaceSpin]);

  const isSlotBusy = useReactiveVar(setIsSlotBusy);
  useEffect(() => {
    let id: NodeJS.Timeout;

    if (!setIsFreeSpinsWin() && !setIsSpinAndGrabWin() && setIsContinueAutoSpinsAfterFeature()) {
      setIsAutoSpins(true);
      setIsContinueAutoSpinsAfterFeature(false);
    }
    if (dataSlotStopped?.isSlotStopped && !isSlotBusy) {
      id = setTimeout(
        () => {
          checkAutoSpinSettings();
        },
        setAutoSpinsLeft() === setAutoSpinsAmount() ? 0 : config.autoplay.timeOut,
      );
    }
    return () => clearTimeout(id);
  }, [isAutoSpins, isFreeSpinsWin, checkAutoSpinSettings, dataSlotStopped?.isSlotStopped, isSlotBusy]);

  useEffect(() => {
    eventManager.on(EventTypes.TOGGLE_SPIN, () => {
      onSpinButtonClick();
    });

    return () => {
      eventManager.removeListener(EventTypes.TOGGLE_SPIN);
    };
  }, [onSpinButtonClick, isAutoSpins, isTurboSpin]);

  return <></>;
};

export default Spin;

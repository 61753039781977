export const gameRules1 = [
  { key: 'infoGameRules1_1', buyFeatureOnly: false },
  { key: 'infoGameRules1_2', buyFeatureOnly: false },
  { key: 'infoGameRules1_3', buyFeatureOnly: false },
];
export const gameRules2 = [
  { key: 'infoGameRules1_4', buyFeatureOnly: false },
  { key: 'infoGameRules1_5', buyFeatureOnly: false },
  { key: 'infoGameRules1_6', buyFeatureOnly: false },
  { key: 'infoGameRules1_7', buyFeatureOnly: false },
  { key: 'infoGameRules1_8', buyFeatureOnly: false },
  { key: 'infoGameRules1_11', buyFeatureOnly: false },
  { key: 'infoGameRules1_12', buyFeatureOnly: false },
];

export const infoFreeSpinsFeatureDescs = [
  'infoFreeSpinsFeatureDescs_1',
  'infoFreeSpinsFeatureDescs_2',
  'infoFreeSpinsFeatureDescs_3',
  'infoFreeSpinsFeatureDescs_4',
  'infoFreeSpinsFeatureDescs_5',
  'infoFreeSpinsFeatureDescs_6',
];

export const infoCollectFeatureDescs = [
  'infoCollectFeatureDescs_1',
  'infoCollectFeatureDescs_2',
  'infoCollectFeatureDescs_3',
  'infoCollectFeatureDescs_4',
];

export const infoSpinAndGrabFeatureDescs = [
  'infoSpinAndGrabFeatureDescs_1',
  'infoSpinAndGrabFeatureDescs_2',
  'infoSpinAndGrabFeatureDescs_3',
  'infoSpinAndGrabFeatureDescs_4',
  'infoSpinAndGrabFeatureDescs_5',
  'infoSpinAndGrabFeatureDescs_6',
  'infoSpinAndGrabFeatureDescs_7',
  'infoSpinAndGrabFeatureDescs_8',
  'infoSpinAndGrabFeatureDescs_9',
];

export const infoFeatureBuy = [
  'infoFeatureBuyDesc_1',
  'infoFeatureBuyDesc_2',
  'infoFeatureBuyDesc_3',
  'infoFeatureBuyDesc_4',
  'infoFeatureBuyDesc_5',
  'infoFeatureBuyDesc_6',
];

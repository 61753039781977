export enum ResourceTypes {
  buyButtonDefault = 'buyButtonDefault',
  buyButtonDisabled = 'buyButtonDisabled',
  buyButtonHover = 'buyButtonHover',
  buyButtonPressed = 'buyButtonPressed',
  buyFeatureConfirmPopupBackplate = 'buyFeatureConfirmPopupBackplate',
  buyFeaturePopupBackplate = 'buyFeaturePopupBackplate',
  buyFeaturePopupConfirm = 'buyFeaturePopupConfirm',
  buyFeaturePopupRage = 'buyFeaturePopupRage',
  buyFeaturePopup = 'buyFeaturePopup',
  buyFeatureDisable = 'buyFeatureDisable',
  buyFeatureHover = 'buyFeatureHover',
  buyFeaturePressed = 'buyFeaturePressed',
  buyFeature = 'buyFeature',
  freeSpinsDefault = 'freeSpinsDefault',
  freeSpinsDisabled = 'freeSpinsDisabled',
  freeSpinsHover = 'freeSpinsHover',
  freeSpinsPressed = 'freeSpinsPressed',
  buyFeatureMinusBtnDisable = 'buyFeatureMinusBtnDisable',
  buyFeatureMinusBtnHover = 'buyFeatureMinusBtnHover',
  buyFeatureMinusBtnPressed = 'buyFeatureMinusBtnPressed',
  buyFeatureMinusBtn = 'buyFeatureMinusBtn',
  buyFeatureOkBtnDisable = 'buyFeatureOkBtnDisable',
  buyFeatureOkBtnHover = 'buyFeatureOkBtnHover',
  buyFeatureOkBtnPressed = 'buyFeatureOkBtnPressed',
  buyFeatureOkBtn = 'buyFeatureOkBtn',
  buyFeaturePlusBtnDisable = 'buyFeaturePlusBtnDisable',
  buyFeaturePlusBtnHover = 'buyFeaturePlusBtnHover',
  buyFeaturePlusBtnPressed = 'buyFeaturePlusBtnPressed',
  buyFeaturePlusBtn = 'buyFeaturePlusBtn',
  subTitleBackplate = 'subTitleBackplate',
  buyFeatureXBtnDisabled = 'buyFeatureXBtnDisabled',
  buyFeatureXBtnHover = 'buyFeatureXBtnHover',
  buyFeatureXBtnPressed = 'buyFeatureXBtnPressed',
  buyFeatureXBtn = 'buyFeatureXBtn',
  counterFreeSpins = 'counterFreeSpins',
  frameFreeSpins = 'frameFreeSpins',
  frameSpinAndGrab = 'frameSpinAndGrab',
  frame = 'frame',
  introBg = 'introBg',
  introReel1 = 'introReel1',
  introReel2 = 'introReel2',
  introReel3 = 'introReel3',
  logo = 'logo',
  reel5Background = 'reel5Background',
  reelBackground = 'reelBackground',
  slotBgFreeSpinsPortrait = 'slotBgFreeSpinsPortrait',
  slotBgFreeSpins = 'slotBgFreeSpins',
  slotBgPortrait = 'slotBgPortrait',
  slotBgSpinAndGrabPortrait = 'slotBgSpinAndGrabPortrait',
  slotBgSpinAndGrab = 'slotBgSpinAndGrab',
  slotBg = 'slotBg',
  spinAndGrabRespins = 'spinAndGrabRespins',
  a = 'a',
  aMotion = 'aMotion',
  c = 'c',
  cMotion = 'cMotion',
  e = 'e',
  eMotion = 'eMotion',
  hp1 = 'hp1',
  hp1Motion = 'hp1Motion',
  hp2 = 'hp2',
  hp2Motion = 'hp2Motion',
  hp3 = 'hp3',
  hp3Motion = 'hp3Motion',
  hp4 = 'hp4',
  hp4Motion = 'hp4Motion',
  j = 'j',
  jMotion = 'jMotion',
  k = 'k',
  kMotion = 'kMotion',
  m = 'm',
  mMotion = 'mMotion',
  q = 'q',
  qMotion = 'qMotion',
  sc1 = 'sc1',
  sc1Motion = 'sc1Motion',
  wl = 'wl',
  wlMotion = 'wlMotion',
  x = 'x',
  xMotion = 'xMotion',
  backdrop = 'backdrop',
  buttonCancel = 'buttonCancel',
  buttonHistory = 'buttonHistory',
  buttonInfo = 'buttonInfo',
  buttonLobby = 'buttonLobby',
  buttonOk = 'buttonOk',
  buttonSetting = 'buttonSetting',
  gameLogo = 'gameLogo',
  infoBtnAutoplayStop = 'infoBtnAutoplayStop',
  infoBtnAutoplay = 'infoBtnAutoplay',
  infoBtnBetSettings = 'infoBtnBetSettings',
  infoBtnInfo = 'infoBtnInfo',
  infoBtnMenu = 'infoBtnMenu',
  infoBtnSound = 'infoBtnSound',
  infoBtnSpin = 'infoBtnSpin',
  infoBtnStop = 'infoBtnStop',
  infoBtnTurbo = 'infoBtnTurbo',
  infoPaylines = 'infoPaylines',
}

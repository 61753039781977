import { ITrackEntry, Spine } from 'pixi-spine';
import { Loader, isMobile } from 'pixi.js';

import { EventTypes } from '../../../global.d';
import { ViewContainer } from '../../components/ViewContainer';
import { eventManager } from '../../config';

import type { SpinAndGrabStartPopup } from './spinAndGrabStartPopup';

class SpinAndGrabStartPopupBackgroundAnimated extends ViewContainer {
  public spine: Spine;

  private spinAndGrabStartPopup: SpinAndGrabStartPopup;

  private showAnimationTrack!: ITrackEntry;

  private hideAnimationTrack!: ITrackEntry;

  constructor(popup: SpinAndGrabStartPopup) {
    super();
    this.spinAndGrabStartPopup = popup;
    this.spine = new Spine(Loader.shared.resources['popups']!.spineData!);
    this.addChild(this.spine);
  }

  protected override resize(width: number, height: number): void {
    const isLandscape = width >= height;
    this.spine.y = isMobile.any && !isLandscape ? -50 : 0;
  }

  public show(): void {
    this.spinAndGrabStartPopup.visible = true;
    this.showAnimationTrack = this.spine.state.setAnimation(0, 'spin_grab_in', false);
    this.spine.state.addAnimation(0, 'spin_grab_loop', true, 0);
  }

  public hide(): void {
    this.hideAnimationTrack = this.spine.state.setAnimation(0, 'spin_grab_out', false);
    this.hideAnimationTrack.listener = {
      complete: (_entry: { trackIndex: number }) => {
        this.spinAndGrabStartPopup.visible = false;
        eventManager.emit(EventTypes.START_SPIN_AND_GRAB);
        this.spine.state.addEmptyAnimation(0, 0, 0);
      },
    };
  }
}

export default SpinAndGrabStartPopupBackgroundAnimated;

import i18n from 'i18next';
import { ITextStyle, Sprite, Texture } from 'pixi.js';

import { EventTypes, GameMode } from '../../global.d';
import { ResourceTypes } from '../../resources.d';
import { TextField } from '../components/TextField';
import { ViewContainer } from '../components/ViewContainer';
import { eventManager } from '../config';

const textStyle: Partial<ITextStyle> = {
  dropShadow: true,
  dropShadowAlpha: 0.8,
  dropShadowAngle: 8,
  dropShadowColor: '#230f05',
  dropShadowDistance: 6,
  fill: '#ffcd00',
  fillGradientStops: [0.6],
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 50,
  lineJoin: 'round',
  miterLimit: 65,
  stroke: '#2d1405',
  strokeThickness: 6,
  whiteSpace: 'normal',
};

export class SpinAndGrabRoundCounter extends ViewContainer {
  private background: Sprite;

  private spins: TextField;

  constructor() {
    super();
    this.x = 630;
    this.y = 50;
    this.visible = false;
    this.background = this.initBackground();
    this.spins = this.initSpins();
    this.addChild(this.background, this.spins.getText());
    eventManager.addListener(EventTypes.UPDATE_SPIN_AND_GRAB_COUNTER, this.updateSpins.bind(this));
  }

  protected override onModeChange(settings: { mode: GameMode }): void {
    switch (settings.mode) {
      case GameMode.BASE_GAME:
        this.visible = false;
        break;
      case GameMode.SPIN_AND_GRAB:
        this.visible = true;
        break;
      default:
        this.visible = false;
        break;
    }
  }

  private updateSpins(spinAndGrabRespins: number): void {
    this.spins.setText(`${i18n.t('spinAndGrabRespinsTitle')}: ${spinAndGrabRespins}`);
    this.spins.setStyle({
      ...textStyle,
    });
  }

  private initBackground(): Sprite {
    const background = new Sprite(Texture.from(ResourceTypes.spinAndGrabRespins));
    background.anchor.set(0.5);
    return background;
  }

  private initSpins(): TextField {
    const text = new TextField(`${i18n.t('spinAndGrabRespinsTitle')}: 3`, 500, 70, textStyle);
    text.text.anchor.set(0.5);
    return text;
  }
}

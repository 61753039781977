import { TEXT_GRADIENT, TextStyle } from 'pixi.js';

export const multiplierTitleTextStyle = new TextStyle({
  fontFamily: 'NotoSans-SemiCondensedBold',
  dropShadowAngle: 1.2,
  // fill: ['#a6925c', '#c8b279', '#ab9363', '#a08b54'],
  // fillGradientStops: [0, 0.4, 0.48, 0.59, 1],
  fill: [
    // '#f08418',
    '#9f6518',
    '#ffeb19',
    '#FBC41C',
    '#F5FF3C',
    '#f08418',
  ],
  fillGradientStops: [0.1, 0.31, 0.42, 0.43, 0.58, 0.65, 0.72, 0.75, 0.8],
  fontSize: 70,
  fontWeight: 'bolder',
  stroke: '#000000',
  strokeThickness: 6,
});

const winMessage = new TextStyle({
  align: 'center',
  breakWords: true,
  dropShadow: true,
  dropShadowAlpha: 0.75,
  dropShadowAngle: 0.785,
  dropShadowBlur: 10,
  dropShadowColor: 0x000000,
  dropShadowDistance: 4,
  fill: [0xffc000, 0xff0000],
  fillGradientType: TEXT_GRADIENT.LINEAR_VERTICAL,
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 140,
  fontStyle: 'normal',
  fontVariant: 'normal',
  fontWeight: 'bold',
  leading: 0,
  letterSpacing: 0,
  lineJoin: 'round',
  miterLimit: 10,
  padding: 4,
  stroke: '#ffffff',
  strokeThickness: 6,
  trim: false,
  textBaseline: 'alphabetic',
  whiteSpace: 'pre',
  wordWrap: false,
  wordWrapWidth: 1330,
});

const winCounterStyle = new TextStyle({
  align: 'center',
  dropShadow: true,
  dropShadowAngle: 2,
  dropShadowColor: '#371a06',
  dropShadowDistance: 3,
  fill: ['#fecc86', '#fbf1d5', '#996e3d', '#a85a00', '#f9be3e', '#64340c'],
  fillGradientStops: [0.3],
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 90,
  stroke: '#663100',
  strokeThickness: 1,
  whiteSpace: 'normal',
});

export const miniPayTableNumberStyle = {
  fontSize: 25,
  fontFamily: 'NotoSans-SemiCondensedBold',
  whiteSpace: 'normal',
  fill: '0xffffff',
};

export const miniPayTableMultipliersStyle = {
  fontSize: 35,
  fontFamily: 'NotoSans-SemiCondensedBold',
  fill: '0xf6e87e',
};

export const bottomContainerTextStyle = {
  fontFamily: 'NotoSans-SemiCondensedBold',
  fill: '#f6e87e',
  dropShadow: true,
  dropShadowAngle: 0.75,
  dropShadowBlur: 3,
  dropShadowColor: '#9b511b',
  dropShadowDistance: 2,
};

export const bigWinStyles = new TextStyle(winMessage);
export const megaWinStyles = new TextStyle(winMessage);
export const greatWinStyles = new TextStyle(winMessage);
export const epicWinStyles = new TextStyle(winMessage);
export const winValueStyles = new TextStyle(winCounterStyle);

// BuyFeature Button Text
export const FEATURE_BTN_TEXT_FIT_TO_WIDTH = 160;
export const FEATURE_BTN_TEXT_X_OFFSET = 0;
export const FEATURE_BTN_TEXT_Y_OFFSET = 2;
export const FEATURE_BTN_TEXT_PRESS_X_OFFSET = FEATURE_BTN_TEXT_X_OFFSET + 1;
export const FEATURE_BTN_TEXT_PRESS_Y_OFFSET = FEATURE_BTN_TEXT_Y_OFFSET + 1;

export const WinTextValueTextStyle = new TextStyle({
  dropShadow: true,
  dropShadowAngle: 8,
  dropShadowColor: '#750000',
  dropShadowDistance: 3,
  fill: ['#ffc05c', '#ffae00', '#bd7400', '#bd7400', '#ad5400', '#e08a00'],
  fillGradientStops: [0.6],
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 95,
  lineJoin: 'round',
  miterLimit: 65,
  stroke: '#4d1700',
  strokeThickness: 9,
  whiteSpace: 'normal',
});

export const buyFeatureTextStyle = new TextStyle({
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontWeight: 'bold',
  dropShadow: true,
  align: 'center',
  dropShadowDistance: 4,
  dropShadowAngle: 1,
  fontSize: 30,
  lineHeight: 30,
  lineJoin: 'round',
  fill: ['#59ff00', '#ffae00'],
  stroke: '#656b3f',
  strokeThickness: 3,
});
export const buyFeatureHoverTextStyle = new TextStyle({
  fontFamily: 'NotoSans-SemiCondensedBold',
  align: 'center',
  dropShadow: true,
  dropShadowColor: '#505050',
  dropShadowDistance: 4,
  dropShadowAngle: 1,
  fontSize: 32,
  lineHeight: 32,
  lineJoin: 'round',
  dropShadowBlur: 10,
  fill: ['#fac558', '#a15906', '#d58a16', '#5a2905'],
  stroke: '#a6bc35',
  strokeThickness: 2,
  miterLimit: 14,
  padding: 6,
});
export const buyFeatureClickTextStyle = new TextStyle({
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontWeight: 'bold',
  align: 'center',
  fontSize: 30,
  lineHeight: 30,
  lineJoin: 'round',
  fill: '#022903',
  miterLimit: 14,
  padding: 6,
  stroke: '#496b0d',
  strokeThickness: 3,
});

export const buyFeatureDisableTextStyle = new TextStyle({
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontWeight: 'bold',
  dropShadow: true,
  align: 'center',
  dropShadowDistance: 4,
  dropShadowAngle: 1,
  fontSize: 30,
  lineHeight: 30,
  lineJoin: 'round',
  fill: '#141d0f',
  stroke: '#656b3f',
  strokeThickness: 3,
});

export const sliderValueTextStyle = { fontSize: 14, fill: 0xeaeaea };

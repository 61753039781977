import _ from 'lodash';

import type { AudioSprite } from '@phoenix7dev/audio-api/dist/d';

export enum ISongs {
  BGM_BG_Base_Loop = '013_01_BGM_BG_Base_Loop',
  BGM_BG_Melo_Loop = '013_02_BGM_BG_Melo_Loop',
  BGM_SG_Loop = '013_03_BGM_SG_Loop',
  BGM_FS_Loop = '013_04_BGM_FS_Loop',
  SG_EntrancePopup = '013_05_SG_EntrancePopup',
  FS_EntrancePopup = '013_06_FS_EntrancePopup',
  WinPopup = '013_07_WinPopup',
  BigWin_Loop = '013_08_BigWin_Loop',
  BigWin_End = '013_09_BigWin_End',
  Win_Big = '013_10_Win_Big',
  Win_Mega = '013_11_Win_Mega',
  Win_Great = '013_12_Win_Great',
  Win_Epic = '013_13_Win_Epic',
  SmallWin = '013_14_SmallWin',
  MediumWin = '013_15_MediumWin',
  HighWin = '013_16_HighWin',
  ScatterLanding = '013_17_ScatterLanding',
  MoneyLanding = '013_18_MoneyLanding',
  CollectLanding = '013_19_CollectLanding',
  CollectWin = '013_21_CollectWin',
  ValuesGathering = '013_22_ValuesGathering',
  SFX_WIN_FeatureTrigger = '013_23_FeatureTrigger',
  LongSpin = '013_24_Anticipation',
  SpinAndGrabDoubleUp = '013_25_DoubleUp',
  BuyButton = '013_26_BuyButton',
  SFX_SM_CountUp_End = 'SFX_SM_CountUp_End',
  SFX_SM_CountUp_Loop = 'SFX_SM_CountUp_Loop',
  SFX_UI_AutoSpin = 'SFX_UI_AutoSpin',
  SFX_UI_BetChange = 'SFX_UI_BetChange',
  SFX_UI_Close = 'SFX_UI_Close',
  SFX_UI_General = 'SFX_UI_General',
  SFX_UI_Hover = 'SFX_UI_Hover',
  SFX_UI_MaxBet = 'SFX_UI_MaxBet',
  SFX_UI_MenuOpen = 'SFX_UI_MenuOpen',
  SFX_UI_SpinStart = 'SFX_UI_SpinStart',
  SFX_UI_SpinStop = 'SFX_UI_SpinStop',
}

export const audioSprite: AudioSprite = {
  [ISongs.BGM_BG_Base_Loop]: [0, 51430.74829931973, true],
  [ISongs.BGM_BG_Melo_Loop]: [53000, 53333.33333333334, true],
  [ISongs.BGM_SG_Loop]: [108000, 52365.351473922885, true],
  [ISongs.BGM_FS_Loop]: [162000, 58440.27210884354, true],
  [ISongs.SG_EntrancePopup]: [222000, 3574.4217687074806],
  [ISongs.FS_EntrancePopup]: [227000, 3577.3242630385485],
  [ISongs.WinPopup]: [232000, 3577.3242630385485],
  [ISongs.BigWin_Loop]: [237000, 28444.444444444456, true],
  [ISongs.BigWin_End]: [267000, 3565.714285714307],
  [ISongs.Win_Big]: [272000, 898.3219954648689],
  [ISongs.Win_Mega]: [274000, 901.2244897959363],
  [ISongs.Win_Great]: [276000, 901.2244897959363],
  [ISongs.Win_Epic]: [278000, 901.2244897959363],
  [ISongs.SmallWin]: [280000, 1786.4852607709736],
  [ISongs.MediumWin]: [283000, 1783.5827664399062],
  [ISongs.HighWin]: [286000, 2677.5510204081456],
  [ISongs.ScatterLanding]: [290000, 1057.9591836734608],
  [ISongs.MoneyLanding]: [293000, 1017.3242630385744],
  [ISongs.CollectLanding]: [296000, 872.1995464852625],
  [ISongs.CollectWin]: [298000, 1516.5532879818784],
  [ISongs.ValuesGathering]: [301000, 1510.7482993197436],
  [ISongs.SFX_WIN_FeatureTrigger]: [304000, 1786.4852607709736],
  [ISongs.LongSpin]: [307000, 2012.879818594115],
  [ISongs.SpinAndGrabDoubleUp]: [311000, 1789.387755102041],
  [ISongs.BuyButton]: [314000, 224.94331065757933],
  [ISongs.SFX_SM_CountUp_End]: [316000, 933.4693877551103],
  [ISongs.SFX_SM_CountUp_Loop]: [318000, 399.77324263037417, true],
  [ISongs.SFX_UI_AutoSpin]: [320000, 320.4988662131427],
  [ISongs.SFX_UI_BetChange]: [322000, 32.108843537400844],
  [ISongs.SFX_UI_Close]: [324000, 200.68027210885475],
  [ISongs.SFX_UI_General]: [326000, 88.34467120180989],
  [ISongs.SFX_UI_Hover]: [328000, 151.79138321997243],
  [ISongs.SFX_UI_MaxBet]: [330000, 122.94784580501528],
  [ISongs.SFX_UI_MenuOpen]: [332000, 277.1428571428487],
  [ISongs.SFX_UI_SpinStart]: [334000, 249.61451247168043],
  [ISongs.SFX_UI_SpinStop]: [336000, 151.02040816327644],
};

export const audioSpriteVolume: Record<ISongs, number> = {
  [ISongs.BGM_BG_Base_Loop]: 0.4,
  [ISongs.BGM_BG_Melo_Loop]: 0.45,
  [ISongs.BGM_SG_Loop]: 0.4,
  [ISongs.BGM_FS_Loop]: 0.4,
  [ISongs.SG_EntrancePopup]: 0.5,
  [ISongs.FS_EntrancePopup]: 0.5,
  [ISongs.WinPopup]: 0.5,
  [ISongs.BigWin_Loop]: 0.5,
  [ISongs.BigWin_End]: 0.4,
  [ISongs.Win_Big]: 0.4,
  [ISongs.Win_Mega]: 0.4,
  [ISongs.Win_Great]: 0.4,
  [ISongs.Win_Epic]: 0.4,
  [ISongs.SmallWin]: 0.25,
  [ISongs.MediumWin]: 0.25,
  [ISongs.HighWin]: 0.25,
  [ISongs.ScatterLanding]: 0.4,
  [ISongs.MoneyLanding]: 0.4,
  [ISongs.CollectLanding]: 0.5,
  [ISongs.CollectWin]: 0.5,
  [ISongs.ValuesGathering]: 0.25,
  [ISongs.SFX_WIN_FeatureTrigger]: 0.6,
  [ISongs.LongSpin]: 0.4,
  [ISongs.SpinAndGrabDoubleUp]: 0.6,
  [ISongs.BuyButton]: 0.3,
  [ISongs.SFX_SM_CountUp_End]: 0.3,
  [ISongs.SFX_SM_CountUp_Loop]: 0.6,
  [ISongs.SFX_UI_AutoSpin]: 0.4,
  [ISongs.SFX_UI_BetChange]: 0.4,
  [ISongs.SFX_UI_Close]: 0.4,
  [ISongs.SFX_UI_General]: 0.4,
  [ISongs.SFX_UI_Hover]: 0.4,
  [ISongs.SFX_UI_MaxBet]: 0.4,
  [ISongs.SFX_UI_MenuOpen]: 0.4,
  [ISongs.SFX_UI_SpinStart]: 0.2,
  [ISongs.SFX_UI_SpinStop]: 0.2,
};

export const audioSpriteGaps: { [key in ISongs]?: number } = {
  // [ISongs.Music_BigWin_End]: 2000,
};

export const mappedAudioSprites = _.reduce(
  audioSprite,
  (acc, value, key) => {
    _.set(acc, key, {
      start: value[0],
      duration: value[1] - (audioSpriteGaps[key as ISongs] || 0),
      loop: !!value[2],
    });
    return acc;
  },
  {},
) as {
  [key in ISongs]: { start: number; duration: number; loop: boolean };
};

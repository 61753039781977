import { ViewContainer } from '../components/ViewContainer';

import FrameStatic from './frameStatic';

class Frame extends ViewContainer {
  public frameStatic!: FrameStatic;

  constructor() {
    super();
    this.sortableChildren = true;
    this.initStatic();
  }

  private initStatic(): void {
    this.frameStatic = new FrameStatic();
    this.addChild(this.frameStatic);
  }
}

export default Frame;

import { useQuery, useReactiveVar } from '@apollo/client';
import type React from 'react';
import { useTranslation } from 'react-i18next';

import { formatNumber } from '@phoenix7dev/utils-fe';

import { config } from '../../config';
import { setCoinValue, setSlotConfig, setUserBalance } from '../../gql/cache';
import { getBetAmountGql } from '../../gql/query';
import { ResourceTypes } from '../../resources.d';
import { countCoins, showCurrency } from '../../utils';
import Resources from '../../utils/resources';

import type { IPaytableData } from './d';
import styles from './info.module.scss';

const calcMultiplier = (multiplier: number, betAmount: number, _slug: ResourceTypes): number => {
  return (
    countCoins({
      totalAmount: betAmount / setSlotConfig().lineSets[0]!.coinAmountMultiplier,
      coinValue: setCoinValue(),
    }) * multiplier
  );
};

const hasTranslate = (slug: ResourceTypes): boolean => {
  return (
    slug === ResourceTypes.wl || slug === ResourceTypes.c || slug === ResourceTypes.sc1 || slug === ResourceTypes.m
  );
};

const PaytableComponent: React.FC = () => {
  const { t } = useTranslation();
  const userBalance = useReactiveVar(setUserBalance);
  const { data: dataBet } = useQuery<{
    betAmount: number;
  }>(getBetAmountGql);

  const { betAmount } = dataBet!;

  const data: IPaytableData[] = config.payTableData;

  const { currency } = userBalance.balance;

  return (
    <div className={styles['paytable-list']}>
      {data.map(({ combos, slug }) => (
        <div key={slug} className={styles['paytable-list__item']}>
          <div className={styles['img']}>
            <img draggable="false" alt={slug} src={Resources.getSource(slug)} />
            {hasTranslate(slug) && <div className={styles['paytable-list__title']}>{t(`infoPayTable_${slug}`)}</div>}
          </div>
          <div className={styles['content']}>
            {combos.map((i) => (
              <div key={i.pattern}>
                <span className={styles['multiplier']}>{i.pattern} </span>
                {formatNumber({
                  currency,
                  value: calcMultiplier(i.multiplier, betAmount, slug),
                  showCurrency: showCurrency(currency),
                })}
              </div>
            ))}
            {slug === 'wl' && <div className={styles['description']}>{t('infoPayTableWildText')}</div>}
            {slug === 'c' && <div className={styles['description']}>{t('infoPayTableCollectText')}</div>}
            {slug === 'm' && <div className={styles['description']}>{t('infoPayTableMoneyText')}</div>}
            {slug === 'sc1' && <div className={styles['description']}>{t('infoPayTableScatterText')}</div>}
          </div>
        </div>
      ))}
    </div>
  );
};

export default PaytableComponent;

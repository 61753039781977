import { ResourceTypes } from '../resources.d';

export const config = {
  failureRetries: 3,
  autoplay: {
    stopOnWinExceedsMinValue: 0,
    stopOnBalanceIncreaseMinValue: 0,
    stopOnBalanceDecreaseMinValue: 0,
    stopOnWinExceedsMaxValue: 500,
    stopOnBalanceIncreaseMaxValue: 5000,
    stopOnBalanceDecreaseMaxValue: 1000,
    defaultValues: {
      isStopOnAnyWin: false,
      isStopOnWinExceeds: false,
      isStopOnBalanceIncrease: false,
      isStopOnBalanceDecrease: false,
      singleWinValue: 25,
      increaseValue: 25,
      decreaseValue: 25,
    },
    autoSpinValues: [25, 50, 75, 100, 500],
    timeOut: 1000,
  },
  betSettings: {
    mockedBets: [20, 50, 75, 100],
    minBet: 20,
  },
  historyTable: {
    showBy: 20,
    head: {
      gameId: 'Game ID',
      date: 'date',
      // balanceStart: 'Start balance',
      bet: 'bet',
      win: 'win',
      // balanceEnd: 'End Balance',
      // detail: 'Detail',
    },
  },
  payTableData: [
    {
      slug: ResourceTypes.m,
      combos: [],
    },
    {
      slug: ResourceTypes.c,
      combos: [],
    },
    {
      slug: ResourceTypes.sc1,
      combos: [{ pattern: 'x3', multiplier: 100 }],
    },
    {
      slug: ResourceTypes.wl,
      combos: [],
    },
    {
      slug: ResourceTypes.hp1,
      combos: [
        { pattern: 'x5', multiplier: 250 },
        { pattern: 'x4', multiplier: 100 },
        { pattern: 'x3', multiplier: 50 },
      ],
    },
    {
      slug: ResourceTypes.hp2,
      combos: [
        { pattern: 'x5', multiplier: 150 },
        { pattern: 'x4', multiplier: 75 },
        { pattern: 'x3', multiplier: 35 },
      ],
    },
    {
      slug: ResourceTypes.hp3,
      combos: [
        { pattern: 'x5', multiplier: 100 },
        { pattern: 'x4', multiplier: 60 },
        { pattern: 'x3', multiplier: 25 },
      ],
    },
    {
      slug: ResourceTypes.hp4,
      combos: [
        { pattern: 'x5', multiplier: 80 },
        { pattern: 'x4', multiplier: 40 },
        { pattern: 'x3', multiplier: 20 },
      ],
    },
    {
      slug: ResourceTypes.a,
      combos: [
        { pattern: 'x5', multiplier: 60 },
        { pattern: 'x4', multiplier: 25 },
        { pattern: 'x3', multiplier: 12 },
      ],
    },
    {
      slug: ResourceTypes.k,
      combos: [
        { pattern: 'x5', multiplier: 60 },
        { pattern: 'x4', multiplier: 25 },
        { pattern: 'x3', multiplier: 12 },
      ],
    },
    {
      slug: ResourceTypes.q,
      combos: [
        { pattern: 'x5', multiplier: 25 },
        { pattern: 'x4', multiplier: 10 },
        { pattern: 'x3', multiplier: 5 },
      ],
    },
    {
      slug: ResourceTypes.j,
      combos: [
        { pattern: 'x5', multiplier: 25 },
        { pattern: 'x4', multiplier: 10 },
        { pattern: 'x3', multiplier: 5 },
      ],
    },
    {
      slug: ResourceTypes.x,
      combos: [
        { pattern: 'x5', multiplier: 10 },
        { pattern: 'x4', multiplier: 4 },
        { pattern: 'x3', multiplier: 2 },
      ],
    },
  ],
};

export enum SlotId {
  A = 'A',
  C = 'C',
  E = 'E',
  J = 'J',
  K = 'K',
  M = 'M',
  Q = 'Q',
  X = 'X',
  SC1 = 'SC1',
  WL = 'WL',
  HP1 = 'HP1',
  HP2 = 'HP2',
  HP3 = 'HP3',
  HP4 = 'HP4',
}

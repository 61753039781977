import i18n from 'i18next';
import { Application, Container, Filter, Sprite, Texture, isMobile, settings } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { introContents } from '../../config/introContents';
import { EventTypes } from '../../global.d';
import {
  setIsProceedToGame,
  setIsShowSoundToast,
  setIsSoundLoading,
  setIsSoundOn,
  setProgress,
  setSkipIntroScreen,
  setSoundValue,
} from '../../gql/cache';
import { Logic } from '../../logic';
import { ResourceTypes } from '../../resources.d';
import { dropShadowFilter, handleChangeRestriction } from '../../utils';
import { eventManager } from '../config';
import Switch from '../controlButtons/switch';

import Carousel from './Carousel';

const GAP_Y = 15;
const GAP_X = 15;

class IntroScreen {
  private readonly application: Application;

  public static init = (): void => {
    new IntroScreen(Logic.the.application);
  };

  private view!: Container;

  private background!: Container;

  private backgroundSprite = new Sprite();

  private textureBg = Texture.from(ResourceTypes.introBg);

  private textureBgPortrait = Texture.from(ResourceTypes.slotBgPortrait);

  private carousel!: Carousel;

  private controlButtons!: Container;

  private okBtn!: Sprite;

  private soundSwitch!: Switch;

  private skipIntroSwitch!: Switch;

  private onResize = this.resize.bind(this);

  private constructor(application: Application) {
    this.application = application;

    this.initBackground();
    this.initView();

    this.application.stage.addChild(this.background);
    this.application.stage.addChild(this.view);

    eventManager.addListener(EventTypes.RESIZE, this.onResize);
  }

  public getApplication(): Application {
    return this.application;
  }

  private initBackground(): void {
    this.background = new Container();
    this.backgroundSprite.texture = this.textureBg;

    this.backgroundSprite.anchor.set(0.5, 0.5);

    this.background.addChild(this.backgroundSprite);
  }

  private initView(): void {
    this.view = new Container();
    this.view.sortableChildren = true;

    this.initCarousel();
    this.initControlButtons();

    this.view.addChild(this.carousel);
    this.view.addChild(this.controlButtons);
  }

  private initCarousel(): void {
    this.carousel = new Carousel(500, introContents);
    this.carousel.zIndex = 2;
  }

  private initControlButtons() {
    this.controlButtons = new Container();

    this.okBtn = this.initOkBtn();
    this.soundSwitch = this.initSoundSwitch();
    this.skipIntroSwitch = this.initSkipIntroSwitch();

    this.controlButtons.addChild(this.okBtn);
    this.controlButtons.addChild(this.soundSwitch);
    this.controlButtons.addChild(this.skipIntroSwitch);
  }

  private initOkBtn = (): Sprite => {
    const btn = new Sprite(Texture.from(ResourceTypes.buttonOk));
    const dropShadow = dropShadowFilter({
      color: 0x000000,
      resolution: 4 * settings.FILTER_RESOLUTION,
      alpha: 0.4,
    }) as Filter;
    const onClickStart = () => {
      btn.filters = [dropShadow];
      btn.height = 49;
    };
    const onClick = () => {
      AudioApi.restrictionChangedOnIntroScreen = true;
      if (!AudioApi.isInitialized) {
        eventManager.emit(EventTypes.ENABLE_SOUND_LOADER);
      }
      AudioApi.changeRestriction(
        false,
        [],
        () => setIsSoundLoading(true),
        () => {
          setIsShowSoundToast(false);
          handleChangeRestriction(Logic.the.controller.gameMode);
        },
      );

      setProgress({ ...setProgress(), wasLoaded: true });

      setTimeout(() => {
        this.destroy();
        eventManager.emit(EventTypes.HANDLE_DESTROY_INTRO_SCREEN);
      });
    };
    const onClickEnd = () => {
      btn.filters = [];
      btn.height = 50;
    };

    btn.anchor.set(0, 0);
    btn.width = 200;
    btn.height = 50;
    btn.y = 0;
    btn.x = 0;
    btn.buttonMode = true;
    btn.interactive = true;

    btn.on('mousedown', onClickStart);
    btn.on('touchstart', onClickStart);
    btn.on('click', onClick);
    btn.on('touchend', onClick);
    btn.on('mouseout', onClickEnd);
    btn.on('touchendoutside', onClickEnd);

    return btn;
  };

  private initSoundSwitch = (): Switch => {
    const soundSwitch = new Switch({ label: i18n.t('sound') });

    soundSwitch.y = this.okBtn.height + GAP_Y;
    soundSwitch.interactive = true;
    soundSwitch.setActive(setIsSoundOn());

    soundSwitch.on('pointerdown', () => {
      const nextState = !setIsSoundOn();
      setIsSoundOn(nextState);
      AudioApi.setSoundState(nextState);
      setSoundValue(nextState ? 1 : 0);
    });

    return soundSwitch;
  };

  private initSkipIntroSwitch = (): Switch => {
    const skipIntroSwitch = new Switch({ label: i18n.t('show') });

    skipIntroSwitch.x = this.soundSwitch.width + GAP_X;
    skipIntroSwitch.y = this.okBtn.height + GAP_Y;
    skipIntroSwitch.interactive = true;

    skipIntroSwitch.on('pointerdown', () => {
      setSkipIntroScreen(!setSkipIntroScreen());
    });

    return skipIntroSwitch;
  };

  private setBackgroundSizeAndPosition = (width: number, height: number): void => {
    const bgAspectRatio = this.backgroundSprite.width / this.backgroundSprite.height;
    const aspectRatio = width / height;

    this.background.x = width / 2;
    this.background.y = height / 2;

    if (bgAspectRatio > aspectRatio) {
      this.background.scale.set(height / this.backgroundSprite.height);
    } else {
      this.background.scale.set(width / this.backgroundSprite.width);
    }
  };

  private setControlButtonsPosition = (width: number): void => {
    this.okBtn.x = (this.controlButtons.width - this.okBtn.width) / 2;

    this.controlButtons.x = width / 2 - this.controlButtons.width / 2;
    this.controlButtons.y = this.carousel.height + GAP_Y;
  };

  private resize(width: number, height: number): void {
    const isPortrait = isMobile.any && height > width;
    if (isPortrait) {
      this.backgroundSprite.texture = this.textureBgPortrait;
    } else {
      this.backgroundSprite.texture = this.textureBg;
    }

    this.setBackgroundSizeAndPosition(width, height);

    this.carousel.setSize(width, height, this.controlButtons.height + GAP_Y * 5);

    this.setControlButtonsPosition(width);

    this.view.y = height / 2 - this.view.height / 2;
    this.application.renderer.resize(width, height);
  }

  private destroy(): void {
    this.application.stage.removeChild(this.background);
    this.application.stage.removeChild(this.view);

    setIsProceedToGame(true);

    eventManager.removeListener(EventTypes.RESIZE, this.onResize);
  }
}

export default IntroScreen;

import { isMobile } from 'pixi.js';

import { EventTypes } from '../../global.d';
import { calcBottomContainerHeight } from '../../utils';
import { ViewContainer } from '../components/ViewContainer';
import {
  FRAME_WIDTH,
  PopupTypes,
  SAFE_AREA_DESKTOP_BOTTOM_PADDING,
  SAFE_AREA_MOBILE_BOTTOM_PADDING,
  SLOTS_CONTAINER_WIDTH,
  eventManager,
} from '../config';

class SafeArea extends ViewContainer {
  constructor() {
    super();
    this.zIndex = 1;
    eventManager.on(EventTypes.OPEN_POPUP, (popupType: PopupTypes) => {
      if (popupType === PopupTypes.BUY_FEATURE || popupType === PopupTypes.BUY_FEATURE_CONFIRMATION) this.zIndex = 10;
    });
    eventManager.on(EventTypes.CLOSE_POPUP, () => {
      this.zIndex = 1;
    });
  }

  private calculateSize(width: number, height: number): number[] {
    let newWidth = 0;
    let newHeight = 0;

    const bottomContainerHeight = calcBottomContainerHeight(width, height);
    const bottomPadding = isMobile.any ? SAFE_AREA_MOBILE_BOTTOM_PADDING : SAFE_AREA_DESKTOP_BOTTOM_PADDING;
    const bottomSpace = bottomContainerHeight + bottomPadding;
    const isLandscape = width >= height;
    if (!isMobile.any && isLandscape) {
      newWidth = ((height - bottomSpace) * 4) / 3;
      newHeight = height - bottomSpace;
      return [newWidth, newHeight, bottomSpace];
    }
    if (!isMobile.any) {
      newWidth = width;
      newHeight = width * 0.75 - bottomSpace;
      return [newWidth, newHeight, bottomSpace];
    }

    if (isLandscape) {
      const additional = width > (height * 5) / 3 ? 0 : 50;
      newWidth = ((height - bottomSpace - additional) * 4) / 3;
      newHeight = height - bottomSpace - additional;
      // if (newWidth + 250 > width) {
      //   newWidth = width - 250;
      //   newHeight = width * 0.75 - bottomSpace + 50;
      // }

      return [newWidth, newHeight, bottomSpace + 100];
    }

    const nWidth = width;
    newWidth = nWidth;
    newHeight = nWidth * 0.75 - bottomSpace + 50;

    return [newWidth, newHeight, bottomSpace + 50];
  }

  protected override resize(width: number, height: number): void {
    const [newWidth, newHeight, bottomSpace] = this.calculateSize(width, height);
    const isLandscape = width >= height;
    const containerWidth = SLOTS_CONTAINER_WIDTH * ((newWidth as number) / FRAME_WIDTH);
    const x = (width - containerWidth) / 2;
    const y = isLandscape
      ? Math.max((height - (newHeight as number) - (bottomSpace as number) / 2) * 0.5, 0)
      : Math.max((height - (newHeight as number) - (bottomSpace as number) / 2) * 0.35, 0);
    this.position.set(x, y);
    eventManager.emit(
      EventTypes.RESIZE_GAME_CONTAINER,
      newWidth,
      newHeight,
      Math.max((width - (newWidth as number)) / 2, 0),
      Math.max((height - (newHeight as number) - (bottomSpace as number)) / 2, 0),
    );
  }
}

export default SafeArea;

import i18n from 'i18next';
import { ITextStyle, Sprite, Texture, isMobile } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs, mappedAudioSprites } from '../../../config';
import { EventTypes } from '../../../global.d';
import { setIsContinueAutoSpinsAfterFeature, setIsDuringBigWinLoop } from '../../../gql/cache';
import { delayedAction } from '../../../utils';
import type Animation from '../../animations/animation';
import { TweenProperties } from '../../animations/d';
import Tween from '../../animations/tween';
import { TextField } from '../../components/TextField';
import type { ViewContainer } from '../../components/ViewContainer';
import { eventManager } from '../../config';
import type { PopupProps } from '../../d';
import { Popup } from '../popup';

import FreeSpinsPopupBackgroundAnimated from './freeSpinsPopupBackgroundAnimated';

const textStyle: Partial<ITextStyle> = {
  dropShadow: true,
  dropShadowAngle: 8,
  dropShadowColor: '#750000',
  dropShadowDistance: 3,
  fill: ['#ffc05c', '#ffae00', '#bd7400', '#bd7400', '#ad5400', '#e08a00'],
  fillGradientStops: [0.6],
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 95,
  lineJoin: 'round',
  miterLimit: 65,
  stroke: '#4d1700',
  strokeThickness: 9,
  whiteSpace: 'normal',
};
const text2Style: Partial<ITextStyle> = {
  align: 'center',
  dropShadowAngle: -1.2,
  dropShadowBlur: 9,
  dropShadowColor: '#fff700',
  dropShadowDistance: 0,
  fill: 'white',
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 100,
  miterLimit: 48,
  stroke: '#2e0000',
  strokeThickness: 1,
  whiteSpace: 'normal',
  wordWrapWidth: 140,
};
const textPress: Partial<ITextStyle> = {
  align: 'center',
  dropShadowAngle: -1.2,
  dropShadowBlur: 9,
  dropShadowColor: '#fff700',
  dropShadowDistance: 0,
  fill: '#1e400a',
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 70,
  stroke: '#2e0000',
  strokeThickness: 1,
  whiteSpace: 'normal',
};

export class FreeSpinsPopup extends Popup {
  protected background!: Sprite;

  protected popupBackground!: FreeSpinsPopupBackgroundAnimated;

  protected text!: TextField;

  protected leftText!: TextField;

  protected pressToContinueText!: TextField;

  protected textContainer!: ViewContainer;

  public fadeOutAnimation!: Animation;

  public fadeInAnimation!: Animation;

  public backgroundFadeInAnimation!: Animation;

  public backgroundFadeOutAnimation!: Animation;

  protected autoSkipDelay: ReturnType<typeof setTimeout> | undefined;

  constructor() {
    super();
    this.interactive = true;
    this.visible = false;
    this.initBackground();
    this.init();
  }

  private initFreeSpinsText(): void {
    this.text = new TextField(i18n.t('freeSpinsYouWonAmount'), 500, 100, textStyle);
    this.text.text.anchor.set(0.5, 0.5);
    this.popupBackground.spine.skeleton.findSlot('10_free_spins').currentSprite.texture = Texture.EMPTY;
    this.popupBackground.spine.skeleton.findSlot('10_free_spins').currentSprite.addChild(this.text.getText());
  }

  private initBackground(): void {
    this.background = new Sprite(Texture.WHITE);
    this.background.tint = 0x000000;
    this.background.anchor.set(0.5, 0.5);
    this.background.alpha = 0.5;
    this.popupBackground = new FreeSpinsPopupBackgroundAnimated(this);
  }

  protected init(): void {
    this.addChild(this.background);
    this.addChild(this.popupBackground);
    this.initFreeSpinsText();
    this.initYouWonText();
    this.initPressToContinueText();
  }

  private initYouWonText(): void {
    this.leftText = new TextField(i18n.t('freeSpinsYouWon'), 500, 100, text2Style);
    this.leftText.text.anchor.set(0.5, 0.5);
    this.popupBackground.spine.skeleton.findSlot('you_won').currentSprite.texture = Texture.EMPTY;
    this.popupBackground.spine.skeleton.findSlot('you_won').currentSprite.addChild(this.leftText.getText());
  }

  private initPressToContinueText(): void {
    this.pressToContinueText = new TextField(i18n.t('freeSpinsPressToContinue'), 500, 100, textPress);
    this.pressToContinueText.text.anchor.set(0.5, 0.5);
    this.popupBackground.spine.skeleton.findSlot('press_anywhere').currentSprite.texture = Texture.EMPTY;
    this.popupBackground.spine.skeleton
      .findSlot('press_anywhere')
      .currentSprite.addChild(this.pressToContinueText.getText());
  }

  private initFadeInAnimation(): Animation {
    return new Tween({
      object: this.textContainer,
      property: TweenProperties.ALPHA,
      propertyBeginValue: 0,
      target: 1,
      duration: 300,
    });
  }

  private initFadeOutAnimation(): Animation {
    return new Tween({
      object: this.textContainer,
      property: TweenProperties.ALPHA,
      propertyBeginValue: 1,
      target: 0,
      duration: 300,
    });
  }

  private initBackGroundFadeInAnimation(): Animation {
    return new Tween({
      object: this.background,
      property: TweenProperties.ALPHA,
      propertyBeginValue: 0,
      target: 0.7,
      duration: 500,
    });
  }

  private initBackGroundFadeOutAnimation(): Animation {
    return new Tween({
      object: this.background,
      property: TweenProperties.ALPHA,
      propertyBeginValue: 0.7,
      target: 0,
      duration: 500,
    });
  }

  public override show(_props?: PopupProps): void {
    AudioApi.play({ type: ISongs.FS_EntrancePopup });
    delayedAction(mappedAudioSprites[ISongs.FS_EntrancePopup].duration, () => {
      AudioApi.play({ type: ISongs.BGM_FS_Loop });
      if (setIsDuringBigWinLoop()) {
        AudioApi.fadeOut(0, ISongs.BGM_FS_Loop);
      }
    });
    this.popupBackground.show();
    this.on('click', this.handleClose);
    this.on('touchstart', this.handleClose);
    eventManager.once(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER, this.handleClose);
    if (setIsContinueAutoSpinsAfterFeature()) {
      this.autoSkipDelay = setTimeout(this.handleClose, 1000);
    }
  }

  private handleClose = () => {
    clearTimeout(this.autoSkipDelay);
    eventManager.removeListener(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER, this.handleClose);
    this.removeListener('click', this.handleClose);
    this.removeListener('touchstart', this.handleClose);
    this.popupBackground.hide();
  };

  protected override resize(width: number, height: number): void {
    this.background.width = width;
    this.background.height = height;
    const isLandscape = width >= height;
    const factor = (isMobile.any && !isLandscape ? 2 : 1) * Math.min(height / 1080, width / 1920);
    this.popupBackground.scale.set(factor);
    this.position.set(width / 2, height / 2);
  }
}

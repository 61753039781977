import AudioApi from '@phoenix7dev/audio-api';

import { ISongs } from '../../config';
import { EnterProps, EventTypes, GameMode, ISettledBet, UserBonus } from '../../global.d';
import {
  setBetAmount,
  setBetResult,
  setBrokenGame,
  setCurrentBonus,
  setCurrentBonusId,
  setFreeSpinsTotalWin,
  setGameHistory,
  setGrabAndSpinTotalWin,
  setIsFreeSpinsWin,
  setIsPopupOpened,
  setIsProceedToGame,
  setIsSlotBusy,
  setIsSpinAndGrabWin,
  setIsSpinInProgress,
  setLastRegularWinAmount,
  setLastSpinData,
  setSkipIntroScreen,
  setSlotConfig,
  setUserBalance,
  setWinAmount,
} from '../../gql/cache';
import client from '../../gql/client';
import { isStoppedGql } from '../../gql/query';
import SlotMachine from '../../slotMachine';
import { WinStages, eventManager } from '../../slotMachine/config';
import IntroScreen from '../../slotMachine/introScreen/introScreen';
import { getBetResult, getSpinResult, getWinStage, normalizeCoins, saveReelPosition } from '../../utils';
import { States } from '../config';
import { Logic } from '../index';

import { Controller } from './Controller';

export class BaseController extends Controller {
  public gameMode: GameMode = GameMode.BASE_GAME;

  public static the = new BaseController();

  private slotIdleTimeout: ReturnType<typeof setTimeout> | undefined;

  protected constructor() {
    super();
  }

  public override enterInitState(_prevState: States): void {
    if (!setSkipIntroScreen()) {
      Logic.the.changeState(States.INTRO);
      return;
    }
    if (setBrokenGame()) {
      Logic.the.changeState(States.BROKEN_GAME);
      return;
    }
    Logic.the.changeState(States.IDLE);
  }

  public override exitInitState(nextState: States): void {
    if (nextState === States.INTRO) return;

    SlotMachine.initSlotMachine(setSlotConfig());
    eventManager.emit(EventTypes.FORCE_RESIZE);
    if (nextState === States.IDLE) {
      setIsProceedToGame(true);
    }
  }

  public override enterIntroState(_prevState: States): void {
    IntroScreen.init();
    eventManager.emit(EventTypes.FORCE_RESIZE);
    eventManager.once(EventTypes.HANDLE_DESTROY_INTRO_SCREEN, () => {
      if (setBrokenGame()) {
        Logic.the.changeState(States.BROKEN_GAME);
        return;
      }
      Logic.the.changeState(States.IDLE);
    });
  }

  public override exitIntroState(_nextState: States): void {
    SlotMachine.initSlotMachine(setSlotConfig());
    eventManager.emit(EventTypes.FORCE_RESIZE);
  }

  public override enterBrokenGameState(_prevState: States): void {
    setIsProceedToGame(true);
    if (!setCurrentBonusId()) {
      const bonus = setCurrentBonus();
      SlotMachine.the().onBrokenGame(bonus);
      Logic.the.changeState(States.TRANSITION);
      Logic.the.changeGameMode(bonus.gameMode, { bonus, immediate: true });
    } else {
      Logic.the.changeState(States.IDLE);
    }
  }

  public override enterIdleState(prevState: States): void {
    if (prevState === States.SPIN) {
      eventManager.emit(EventTypes.SET_CURRENT_RESULT_MINI_PAYTABLE);
      eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);

      setIsSpinInProgress(false);
      setIsSlotBusy(false);
      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: true,
        },
      });
      return;
    }
    if (prevState === States.TRANSITION) {
      setIsSpinInProgress(false);
      eventManager.emit(EventTypes.SET_CURRENT_RESULT_MINI_PAYTABLE);
      if (setCurrentBonusId()) {
        return;
      }
    }

    if (prevState === States.INIT || prevState === States.INTRO) {
      // const debug = new Debug();
      // Logic.the.application.stage.addChild(debug);
      // Logic.the.application.ticker.add(() => debug.update());
      return;
    }
    if (prevState === States.BROKEN_GAME) {
      const intervalId = window.setInterval(() => {
        if (eventManager.listenerCount(EventTypes.TOGGLE_SPIN) > 0) {
          setIsPopupOpened(false);
          eventManager.emit(EventTypes.TOGGLE_SPIN);
          window.clearInterval(intervalId);
        }
      }, 50);
      return;
    }
    this.slotIdleTimeout = setTimeout(() => {
      AudioApi.fadeOut(3000, ISongs.BGM_BG_Melo_Loop);
      AudioApi.fadeIn(3000, ISongs.BGM_BG_Base_Loop);
    }, 30000);
    eventManager.emit(EventTypes.SET_CURRENT_RESULT_MINI_PAYTABLE);
    setIsSpinInProgress(false);
    setIsSlotBusy(false);
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
    eventManager.emit(EventTypes.UPDATE_USER_BALANCE, getBetResult(setBetResult()).balance.settled);

    client.writeQuery({
      query: isStoppedGql,
      data: {
        isSlotStopped: true,
      },
    });
    this.handleHistory(prevState === States.TRANSITION);
  }

  public override enterSpinState(_prevState: States): void {
    clearTimeout(this.slotIdleTimeout);
    eventManager.emit(EventTypes.DISABLE_PAYTABLE);
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, true);
    SlotMachine.the().spinSpinAnimation();
  }

  public override enterBeforeWinState(_prevState: States): void {
    client.writeQuery({
      query: isStoppedGql,
      data: {
        isSlotStopped: false,
      },
    });
    const betResult = getBetResult(setBetResult());
    if (this.isCollectFeature(betResult)) {
      eventManager.once(EventTypes.END_COLLECT_FEATURE, () => this.checkBaseWin(betResult));
      eventManager.emit(EventTypes.START_COLLECT_FEATURE, betResult.bet.data.features.moneyCollectFeature);
    } else {
      this.checkBaseWin(betResult);
    }
  }

  public isCollectFeature(result: ISettledBet): boolean {
    return (
      !!result.bet.data.features.moneyCollectFeature?.collectSymbols.length &&
      !!result.bet.data.features.moneyCollectFeature?.moneySymbols.length
    );
  }

  public override enterWinLinePresentationState(_prevState: States): void {
    const betResult: ISettledBet = getBetResult(setBetResult());
    const { paylines } = betResult;
    const { winCoinAmount } = betResult.bet.result;
    const multiplier = normalizeCoins(winCoinAmount) / normalizeCoins(setBetAmount());
    if (multiplier >= 5 && multiplier < 10) {
      AudioApi.play({ type: ISongs.HighWin, stopPrev: true });
    }
    if (multiplier >= 2 && multiplier < 5) {
      AudioApi.play({ type: ISongs.MediumWin, stopPrev: true });
    }
    if (multiplier > 0 && multiplier < 2) {
      AudioApi.play({ type: ISongs.SmallWin, stopPrev: true });
    }

    if (getWinStage(winCoinAmount) >= WinStages.BigWin) {
      eventManager.once(EventTypes.COUNT_UP_END, () => {
        Logic.the.changeState(States.AFTER_WIN);
      });
      eventManager.emit(EventTypes.START_BIG_WIN_PRESENTATION, winCoinAmount);
    } else {
      eventManager.once(EventTypes.WIN_LINE_ANIMATION_END, () => {
        Logic.the.changeState(States.AFTER_WIN);
      });
      eventManager.emit(EventTypes.START_COUNT_UP, 0, winCoinAmount, 0);
    }

    eventManager.emit(EventTypes.START_WIN_ANIMATION, betResult, paylines);
  }

  public override enterAfterWinState(_prevState: States): void {
    eventManager.emit(EventTypes.HIDE_COUNT_UP);
    const { winCoinAmount } = getBetResult(setBetResult()).bet.result;
    setWinAmount(winCoinAmount);
    setLastRegularWinAmount(winCoinAmount);
    setTimeout(() => Logic.the.changeState(States.JINGLE), 500);
  }

  public override enterJingleState(_prevState: States): void {
    const result = getBetResult(setBetResult());
    const isFeatureWin = result.bet.data.bonuses.length > 0;

    if (isFeatureWin) {
      const [bonus] = result.bet.data.bonuses;
      const isFreeSpinsFeature = Boolean(!(bonus as UserBonus).data.spinAndGrabFeature);
      const isSpinAndGrabFeature = Boolean((bonus as UserBonus).data.spinAndGrabFeature);

      if (isFreeSpinsFeature) {
        setIsFreeSpinsWin(true);
        setCurrentBonus({
          ...(bonus as UserBonus),
          isActive: true,
          currentRound: 0,
        });
        setFreeSpinsTotalWin(result.bet.result.winCoinAmount);
        setTimeout(() => {
          Logic.the.skipWinAnimation();
          Logic.the.changeState(States.TRANSITION);
          Logic.the.changeGameMode(GameMode.FREE_SPINS, {
            bonus: bonus as UserBonus,
          });
        }, 1000);
      }

      if (isSpinAndGrabFeature) {
        setIsSpinAndGrabWin(true);
        setTimeout(() => {
          setGrabAndSpinTotalWin(result.bet.result.winCoinAmount);
          setCurrentBonus({
            ...(bonus as UserBonus),
          });
          Logic.the.skipWinAnimation();
          Logic.the.changeState(States.TRANSITION);
          Logic.the.changeGameMode(GameMode.SPIN_AND_GRAB, {
            bonus: bonus as UserBonus,
          });
        }, 1000);
      }

      return;
    }
    Logic.the.changeState(States.IDLE);
  }

  public override enterController(prevGameMode: GameMode, _props?: EnterProps): void {
    if (prevGameMode !== GameMode.BUY_FEATURE) {
      AudioApi.play({ type: ISongs.BGM_BG_Base_Loop });
      AudioApi.play({ type: ISongs.BGM_BG_Melo_Loop, volume: 0 });
    }
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
    eventManager.emit(EventTypes.IMMEDIATE_CLOSE_EYES);
    eventManager.on(EventTypes.HANDLE_BUY_BONUS, (bonusId: string) => {
      Logic.the.changeState(States.TRANSITION);
      Logic.the.changeGameMode(GameMode.BUY_FEATURE, { bonusId });
    });
    if (prevGameMode === null) return;
    setIsFreeSpinsWin(false);
    setIsSpinAndGrabWin(false);
    if (prevGameMode === GameMode.FREE_SPINS) {
      setWinAmount(setFreeSpinsTotalWin());
    }
    if (prevGameMode === GameMode.SPIN_AND_GRAB) {
      eventManager.emit(EventTypes.UPDATE_USER_BALANCE, getBetResult(setBetResult()).balance.settled);
      this.handleHistory(true);
    }
    Logic.the.changeState(States.IDLE);
  }

  public override exitController(nextGameMode: GameMode): void {
    if (nextGameMode !== GameMode.BUY_FEATURE) {
      AudioApi.stop({ type: ISongs.BGM_BG_Base_Loop });
      AudioApi.stop({ type: ISongs.BGM_BG_Melo_Loop });
    }
    clearTimeout(this.slotIdleTimeout);
    eventManager.removeListener(EventTypes.HANDLE_BUY_BONUS);
  }

  public override setResult(result: ISettledBet): void {
    eventManager.emit(EventTypes.UPDATE_USER_BALANCE, result.balance.placed);
    setUserBalance({ ...setUserBalance(), balance: result.balance.placed });

    result.bet.result.spinResult = getSpinResult({
      reelPositions: result.bet.result.reelPositions,
      reelSet: result.bet.reelSet,
      icons: setSlotConfig().icons,
    });
    setBetResult(result);

    setLastSpinData({
      layout: [],
      reelPositions: getBetResult(setBetResult()).bet.result.reelPositions,
    });
  }

  private handleHistory(skipSoundChange: boolean): void {
    const betResult = getBetResult(setBetResult());
    const win = betResult.bet.result.winCoinAmount;
    const lastThreeSpins = [...setGameHistory().slice(1), !!win];
    const isThirdWinInRow = lastThreeSpins.every((val) => Boolean(val));
    const isThirdLoseInRow = lastThreeSpins.every((val) => !val);
    const isWinThreeTimesBiggerThanBet = setBetAmount() * 3 <= win;

    if (!skipSoundChange) {
      if (isWinThreeTimesBiggerThanBet || isThirdWinInRow) {
        AudioApi.fadeOut(500, ISongs.BGM_BG_Base_Loop);
        AudioApi.fadeIn(500, ISongs.BGM_BG_Melo_Loop);
      }

      if (isThirdLoseInRow) {
        AudioApi.fadeOut(3000, ISongs.BGM_BG_Melo_Loop);
        AudioApi.fadeIn(3000, ISongs.BGM_BG_Base_Loop);
      }
    }

    setGameHistory(lastThreeSpins);
    setUserBalance({ ...setUserBalance(), balance: betResult.balance.settled });
    saveReelPosition(betResult.bet.result.reelPositions);
  }

  private checkBaseWin(betResult: ISettledBet): void {
    let paylines;
    if (betResult.bet.data.features.moneyCollectFeature?.collectSymbols.length) {
      paylines = betResult.paylines.filter(
        (payline) =>
          !payline.winPositions.includes(betResult.bet.data.features.moneyCollectFeature.collectSymbols[0]!.position),
      );
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      paylines = betResult.paylines;
    }
    if (betResult.paylines.length) {
      Logic.the.changeState(States.WIN_LINE_PRESENTATION);
    } else {
      Logic.the.changeState(States.IDLE);
    }
  }
}

import { Sprite, Texture, isMobile } from 'pixi.js';

import { ResourceTypes } from '../../../resources.d';
import { ViewContainer } from '../../components/ViewContainer';
import { FEATURE_POPUP_HEIGHT, FEATURE_POPUP_WIDTH } from '../../config';

class BuyFeaturePopupConfirmBackgroundStatic extends ViewContainer {
  private popupBg: Sprite;

  constructor() {
    super();
    this.popupBg = this.initPopupBg();
    this.init();
  }

  private init(): void {
    this.addChild(this.popupBg);
  }

  private initPopupBg(): Sprite {
    const popupBg = new Sprite(Texture.from(ResourceTypes.buyFeaturePopupConfirm));
    popupBg.anchor.set(0.5, 0.5);
    popupBg.width = isMobile.any ? 1150 : FEATURE_POPUP_WIDTH;
    popupBg.height = isMobile.any ? 925 : FEATURE_POPUP_HEIGHT;
    popupBg.x = isMobile.any ? 551 : 560;
    popupBg.y = isMobile.any ? 358 : 355;

    return popupBg;
  }

  public handleChangeBg(texture: string): void {
    this.popupBg.texture = Texture.from(texture);
  }
}

export default BuyFeaturePopupConfirmBackgroundStatic;

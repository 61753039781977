import _ from 'lodash';
import { Container, Graphics } from 'pixi.js';

import { MAPPED_SYMBOLS, SlotId, config } from '../../config';
import { EventTypes } from '../../global.d';
import { setIsMiniPayTable } from '../../gql/cache';
import {
  PAY_TABLE_BACKGROUND_COLOR,
  REELS_AMOUNT,
  REEL_WIDTH,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  SLOTS_PER_REEL_AMOUNT,
  SLOT_HEIGHT,
  SLOT_WIDTH,
  eventManager,
} from '../config';
import type { Combos, Icon } from '../d';
import type Slot from '../reels/slot';

import MiniPayTable from './miniPayTable';

class MiniPayTableContainer extends Container {
  private getSlotById: CallableFunction;

  private payTableContainers: Container[];

  private combosMap: Record<SlotId, Combos>;

  private icons: Icon[] = [];

  constructor(icons: Icon[], initialPaytableConfig: Icon[], getSlotByIdFn: CallableFunction) {
    super();
    this.icons = icons;
    this.width = SLOTS_CONTAINER_WIDTH;
    this.height = SLOTS_CONTAINER_HEIGHT;
    this.sortableChildren = true;
    this.getSlotById = getSlotByIdFn;
    this.visible = setIsMiniPayTable();
    eventManager.addListener(EventTypes.DISABLE_PAYTABLE, () => (this.visible = false));
    eventManager.addListener(EventTypes.ENABLE_PAYTABLE, () => (this.visible = true));
    this.payTableContainers = [];

    this.combosMap = _.mapValues(
      MAPPED_SYMBOLS,
      (slug) => config.payTableData.find((d) => d.slug === slug.default)?.combos as Combos,
    );
    this.createContainers();
    this.updateMiniPaytable(initialPaytableConfig);
  }

  private createContainers() {
    for (let row = 0; row < SLOTS_PER_REEL_AMOUNT; row++) {
      for (let col = 0; col < REELS_AMOUNT; col++) {
        const index = row * REELS_AMOUNT + col;
        const slot = this.getSlotById(index) as Slot;
        const icon = this.icons.find((icon) => icon.id === slot.slotId)!;
        const container = new Container();
        const rect = new Graphics();
        container.width = 300;
        container.height = 240;
        container.x = this.xPosition(row, col);
        container.y = this.yPosition(row, col);
        container.zIndex = 1;
        container.interactive = true;
        rect.beginFill(PAY_TABLE_BACKGROUND_COLOR);
        rect.drawRect(0, 0, SLOT_WIDTH, SLOT_HEIGHT);
        rect.alpha = 0;
        container.addChild(rect);
        container.on('mousedown', () => {
          eventManager.emit(EventTypes.SHOW_PAY_TABLE, index);
        });
        container.on('touchstart', () => eventManager.emit(EventTypes.SHOW_PAY_TABLE, index));
        const payTable = new MiniPayTable(index, icon, this.combosMap[icon.id]);
        container.addChild(payTable);
        this.payTableContainers.push(container);
        this.addChild(container);
      }
    }
  }

  private xPosition(_row: number, col: number): number {
    const positionValue = REEL_WIDTH;
    return col * positionValue;
  }

  private yPosition(row: number, _col: number): number {
    return SLOT_HEIGHT * row;
  }

  public updateMiniPaytable(spinResult: Icon[]): void {
    spinResult.forEach((icon, index: number) => {
      const container = this.payTableContainers[index as number];
      const payTable = container!.children[1] as MiniPayTable;
      const iconId = icon.id;
      payTable.setPayTableData(iconId, this.combosMap[iconId as SlotId]);
    });
    this.visible = true;
  }
}

export default MiniPayTableContainer;

import { Spine } from 'pixi-spine';
import { Container, Loader, Sprite, Spritesheet, Texture } from 'pixi.js';

import { MAPPED_BLURRED_SYMBOLS, MAPPED_SYMBOLS, MAPPED_SYMBOLS_LAND_ANIMATIONS, SlotId } from '../../config';
import { EventTypes, GameMode } from '../../global.d';
import { setCurrentBonus, setCurrentSpinAndGrabRound, setSpinAndGrabRoundsLeft } from '../../gql/cache';
import { Logic } from '../../logic/index';
import { getFromMappedSymbol, getRandomBagMultiplier, nextTick } from '../../utils';
import { multiplierTextStyle } from '../buyFeature/textStyles';
import { TextField } from '../components/TextField';
import {
  INITIAL_SPIN_AND_GRAB_ROUNDS_NUMBER,
  SLOTS_PER_REEL_AMOUNT,
  SLOT_HEIGHT,
  SLOT_SCALE,
  SLOT_WIDTH,
  eventManager,
} from '../config';

class Slot extends Container {
  public id: number;

  public slotId: SlotId;

  private reelId: number | undefined;

  public textureSlotId: SlotId;

  public slot: Sprite;

  private landAnimation!: Spine;

  public multiplierValue = 0;

  public slotMoneyMultiplier: Sprite | undefined;

  public slotCollectMultiplier: TextField | null = null;

  private multipliers: Spritesheet;

  constructor(id: number, slotId: SlotId, reelId?: number) {
    super();
    this.id = id;
    this.slotId = slotId;
    this.reelId = reelId;
    this.textureSlotId = slotId;
    this.width = SLOT_WIDTH * SLOT_SCALE;
    this.height = SLOT_HEIGHT * SLOT_SCALE;
    this.y = (SLOTS_PER_REEL_AMOUNT - id - 0.5) * SLOT_HEIGHT;
    this.x = SLOT_WIDTH / 2;
    this.zIndex = 1;
    this.slot = this.initSlot();
    this.addChild(this.slot);
    this.multipliers = Loader.shared.resources!['multipliersSprite']!.spritesheet!;
    this.initMoneyMultiplier();
  }

  private initSlot(): Sprite {
    const slot = new Sprite(Texture.from(getFromMappedSymbol(MAPPED_SYMBOLS, this.slotId)));
    slot.anchor.set(0.5, 0.5);
    return slot;
  }

  private initMoneyMultiplier(): void {
    if (this.slotId === SlotId.M) {
      this.multiplierValue =
        Logic.the.controller.gameMode === GameMode.SPIN_AND_GRAB
          ? (setCurrentBonus().data.spinAndGrabFeature?.startingCells[this.reelId as number]!.value as number)
          : getRandomBagMultiplier();
      if (this.multiplierValue) {
        this.slotMoneyMultiplier = new Sprite(this.multipliers.textures[this.multiplierValue]);
        this.slotMoneyMultiplier.name = 'slotMoneyMultiplier';
        this.slotMoneyMultiplier.anchor.set(0.5);
        this.slot.addChild(this.slotMoneyMultiplier);
      }
    }
  }

  public removeMoneyMultiplier(): void {
    this.slot.removeChild(this.slotMoneyMultiplier!);
  }

  public updateMoneyMultiplier(value: number): void {
    this.multiplierValue = value;
    this.slotMoneyMultiplier!.texture = this.multipliers.textures[value as number]!;
  }

  public changeTexture(slotId: SlotId): void {
    this.slot.texture = Texture.from(getFromMappedSymbol(MAPPED_SYMBOLS, slotId));
    this.textureSlotId = slotId;
  }

  public changeSlot(slotId: SlotId): void {
    this.changeTexture(slotId);
    this.slotId = slotId;
  }

  public toggleBlur(blur: boolean): void {
    if (blur) {
      this.slot.texture = Texture.from(MAPPED_BLURRED_SYMBOLS[this.textureSlotId].default);
    } else {
      this.slot.texture = Texture.from(MAPPED_SYMBOLS[this.textureSlotId].default);
    }
  }

  public onSlotStopped(): void {
    // @TODO ADD add sound/animation on slot stop
    this.playLandAnim();
  }

  private playLandAnim(): void {
    if (MAPPED_SYMBOLS_LAND_ANIMATIONS[this.slotId]) {
      const animationSrc = MAPPED_SYMBOLS_LAND_ANIMATIONS[this.slotId]?.src;
      const animationName = MAPPED_SYMBOLS_LAND_ANIMATIONS[this.slotId]?.animation;
      this.landAnimation = new Spine(Loader.shared.resources![animationSrc!]!.spineData!);

      if (this.slotId === SlotId.M) {
        if (Logic.the.controller.gameMode === GameMode.SPIN_AND_GRAB) {
          const moneySymbol = setCurrentSpinAndGrabRound().newCells.filter((cell) => cell.position === this.reelId)[0];
          if (moneySymbol) {
            this.multiplierValue = moneySymbol.value as number;
          }
        }
        const placeholder = this.landAnimation.skeleton.findSlot('multiplayers_money').currentSprite as Sprite;
        placeholder.texture = this.multipliers.textures[this.multiplierValue]!;
      }
      this.landAnimation.state.addListener({
        start: () => {
          nextTick(() => {
            this.slot.visible = false;
          });
        },
        complete: () => {
          nextTick(() => {
            if (Logic.the.controller.gameMode === GameMode.SPIN_AND_GRAB && this.slotId === SlotId.M) {
              this.slotMoneyMultiplier = new Sprite(this.multipliers.textures[this.multiplierValue]);
              this.slotMoneyMultiplier.anchor.set(0.5);
              this.slot.addChild(this.slotMoneyMultiplier);
            }
            if (this.landAnimation.state) this.landAnimation.destroy();
            this.slot.visible = true;
            eventManager.emit(EventTypes.REEL_LANDED_ANIMATION_PLAYED);
          });
        },
      });
      this.landAnimation.state.setAnimation(0, animationName!, false);
      this.addChild(this.landAnimation);
      if (Logic.the.controller.gameMode === GameMode.SPIN_AND_GRAB) {
        if (setSpinAndGrabRoundsLeft() !== INITIAL_SPIN_AND_GRAB_ROUNDS_NUMBER)
          eventManager.emit(
            EventTypes.UPDATE_SPIN_AND_GRAB_COUNTER,
            setSpinAndGrabRoundsLeft(INITIAL_SPIN_AND_GRAB_ROUNDS_NUMBER),
          );
      }
    } else {
      eventManager.emit(EventTypes.REEL_LANDED_ANIMATION_PLAYED);
    }
  }

  public setCollectMultiplier(collectorValue: number): void {
    this.multiplierValue = collectorValue;
    const multiplierText = new TextField(`x${this.multiplierValue}`, SLOT_WIDTH, SLOT_HEIGHT, multiplierTextStyle);
    this.slotCollectMultiplier = multiplierText;
    multiplierText.text.anchor.set(0.5, 0.5);
    this.addChild(multiplierText.getText());
  }

  public resetCollectMultiplier(): void {
    if (this.slotCollectMultiplier) {
      this.removeChild((this.slotCollectMultiplier as TextField).getText());
    }
    if (this.landAnimation) {
      this.removeChild(this.landAnimation);
    }
    if (this.children[0]) {
      (this.children[0] as Sprite).removeChildren();
    }
  }
}

export default Slot;

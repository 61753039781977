import { Sprite, Texture } from 'pixi.js';

import { GameMode } from '../../../global.d';
import { ResourceTypes } from '../../../resources.d';
import { ViewContainer } from '../../components/ViewContainer';
import { REELS_AMOUNT, REEL_WIDTH, SLOTS_PER_REEL_AMOUNT, SLOT_HEIGHT } from '../../config';

class GrabAndSpinReelsBackgroundContainer extends ViewContainer {
  private spinAndGrabBackground: Sprite;

  constructor() {
    super();
    this.spinAndGrabBackground = this.initSpinAndGrabFrame();
    this.addChild(this.spinAndGrabBackground);
  }

  protected override onModeChange(settings: { mode: GameMode }): void {
    this.spinAndGrabBackground.visible = settings.mode === GameMode.SPIN_AND_GRAB;
  }

  initSpinAndGrabFrame(): Sprite {
    const texture = Texture.from(ResourceTypes.frameSpinAndGrab);
    const sprite = new Sprite(texture);
    sprite.width = REELS_AMOUNT * REEL_WIDTH;
    sprite.height = SLOTS_PER_REEL_AMOUNT * SLOT_HEIGHT;
    sprite.visible = false;
    return sprite;
  }
}

export default GrabAndSpinReelsBackgroundContainer;

import { Sprite, Texture } from 'pixi.js';

import { ResourceTypes } from '../../../resources.d';
import { ViewContainer } from '../../components/ViewContainer';
import { REELS_AMOUNT, REEL_WIDTH, SLOTS_PER_REEL_AMOUNT, SLOT_HEIGHT } from '../../config';

class ReelsBackgroundContainer extends ViewContainer {
  private background: Sprite;

  constructor() {
    super();
    this.background = this.initBackground();
    this.addChild(this.background);
  }

  initBackground(): Sprite {
    const texture = Texture.from(ResourceTypes.reelBackground);
    const sprite = new Sprite(texture);
    sprite.width = REELS_AMOUNT * REEL_WIDTH;
    sprite.height = SLOTS_PER_REEL_AMOUNT * SLOT_HEIGHT;
    return sprite;
  }
}

export default ReelsBackgroundContainer;

import type { Group } from '@pixi/layers';
import { Graphics } from 'pixi.js';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs, SlotId } from '../../../config';
import { setCurrentSpinAndGrabRound } from '../../../gql/cache';
import { normalizePosition } from '../../../utils';
import AnimationChain from '../../animations/animationChain';
import { TweenProperties } from '../../animations/d';
import { BaseAnimation } from '../../animations/reel/baseAnimation';
import type { ReelAnimation } from '../../animations/reel/reelAnimation';
import Tween from '../../animations/tween';
import { ViewContainer } from '../../components/ViewContainer';
import { REEL_WIDTH, ReelState, SLOT_HEIGHT, SLOT_WIDTH, SPIN_AND_GRAB_SLOTS_PER_REEL_AMOUNT } from '../../config';
import Slot from '../slot';

import type { Reel } from './reel';

export class GrabAndSpinReel extends ViewContainer implements Reel {
  public id: number;

  public state: ReelState;

  public data: SlotId[];

  public slots: Slot[] = [];

  public size: number;

  public isPlaySoundOnStop: boolean;

  public animation: ReelAnimation | null = null;

  public stopPosition: number;

  public isFreezed: boolean;

  constructor(id: number, data: SlotId[], startPosition: number, slotGroup: Group) {
    super();
    this.id = id;
    this.data = data;
    this.size = this.data.length;
    this.state = ReelState.IDLE;
    this.isFreezed = data[startPosition as number] !== SlotId.E;
    this.isPlaySoundOnStop = true;
    this.sortableChildren = true;
    this.stopPosition = startPosition;
    this.width = SLOT_WIDTH;
    this.height = SLOT_HEIGHT;
    this.x = (this.id % 5) * REEL_WIDTH;
    this.y = SLOT_HEIGHT * Math.floor(this.id / 5) - SLOT_HEIGHT;
    this.createSlots(slotGroup);
    this.mask = new Graphics().beginFill(0xffffff).drawRect(0, 0, SLOT_WIDTH, SLOT_HEIGHT).endFill();
    this.mask.y = SLOT_HEIGHT;
    this.addChild(this.mask);
  }

  public createSlots(slotGroup: Group): void {
    for (let i = 0; i < this.data.length; i++) {
      const slot = new Slot(i, this.data[i as number] as SlotId, this.id);
      this.slots.push(slot);
      slot.parentGroup = slotGroup;
      slot.y = this.getSlotY(slot);

      this.addChild(slot);
    }
  }

  public getSlotY(slot: Slot): number {
    return SLOT_HEIGHT * (((this.data.length + slot.id + 1 - this.stopPosition) % this.data.length) + 0.5);
  }

  private onReelEnding(_previousState: ReelState, _newState: ReelState): void {
    this.toggleBlurSlots(false);
  }

  private onReelStop(): void {
    const reelValue = setCurrentSpinAndGrabRound().newCells.filter((cell) => cell.position === this.id)[0];

    if (reelValue) {
      AudioApi.play({
        type: reelValue.symbol === SlotId.M ? ISongs.MoneyLanding : ISongs.CollectLanding,
        stopPrev: true,
      });
    } else {
      AudioApi.play({ type: ISongs.SFX_UI_SpinStop, stopPrev: true });
    }
  }

  private onReelIdle(previousState: ReelState, _newState: ReelState): void {
    if (previousState === ReelState.APPEARING) {
      this.onReelStop();
    }
  }

  private onReelRolling(_previousState: ReelState, _newState: ReelState): void {}

  private onReelStarting(_previousState: ReelState, _newState: ReelState): void {
    this.toggleBlurSlots(true);
  }

  public changeState(newState: ReelState): void {
    const previousState = this.state;
    this.state = newState;
    if (newState === ReelState.IDLE) {
      this.onReelIdle(previousState, ReelState.IDLE);
    }
    if (newState === ReelState.DISAPPEARING) {
      this.onReelRolling(previousState, ReelState.DISAPPEARING);
    }
    if (newState === ReelState.WAITING) {
      this.onReelStarting(previousState, ReelState.WAITING);
    }
    if (newState === ReelState.APPEARING) {
      this.onReelEnding(previousState, ReelState.APPEARING);
    }
  }

  public createSpinAnimation(): ReelAnimation {
    const onChange = () => {
      this.slots.forEach((slot) => {
        slot.y = this.getSlotY(slot);
      });
    };

    const disappearingAnimation = new AnimationChain();
    disappearingAnimation.appendAnimation(Tween.createDelayAnimation(200));
    const disappearingTarget = normalizePosition(this.size, this.stopPosition - SPIN_AND_GRAB_SLOTS_PER_REEL_AMOUNT);
    const disappearingBegin = this.stopPosition;
    disappearingAnimation.appendAnimation(
      new Tween({
        object: this,
        property: TweenProperties.STOP_POSITION,
        propertyBeginValue: disappearingBegin,
        target: disappearingTarget,
        duration: 500,
      }),
    );
    disappearingAnimation.addOnStart(() => this.changeState(ReelState.DISAPPEARING));
    disappearingAnimation.addOnChange(onChange);
    const speed = 15;
    const duration = 12000;
    const waitingTarget = disappearingTarget - (speed * duration) / 1000;
    const waitingAnimation = new Tween({
      object: this,
      property: TweenProperties.STOP_POSITION,
      propertyBeginValue: disappearingTarget,
      target: waitingTarget,
      duration,
    });
    waitingAnimation.addOnStart(() => {
      this.changeState(ReelState.WAITING);
    });
    waitingAnimation.addOnChange(onChange);
    this.animation = new BaseAnimation({
      disappearingAnimation,
      waitingAnimation,
    });
    return this.animation;
  }

  private toggleBlurSlots(enabled: boolean): void {
    this.slots.forEach((slot) => {
      slot.toggleBlur(enabled);
    });
  }

  changeReelData(_data: SlotId[], _slotGroup: Group, _position: number): void {}
}
